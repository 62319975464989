.back_btn {
  margin-top: 15px;

  a {
    span {
      margin-right: 5px;
    }
  }

  .back_btn_icon {
    img {
      width: 10px;
      transform: rotate(90deg);
      margin-bottom: 2px;
    }
  }
}

.top_select_btn_search {
  border-bottom: 1px solid #202020;

  .search_part {
    .search {
      .search_main {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin: 17px 0 10px;

        input {
          max-width: 365px;
          width: 100%;
          border: none;
          background: $secondary-bg;
          height: 44px;
          padding: 0px 45px 0px 15px;
          color: #ffffff;
          font-size: $font14;
          border-radius: 6px;

          &:focus {
            outline: none;
          }
        }

        svg {
          position: absolute;
          right: 15px;
          max-height: 16px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
  }

  .btn_group {
    button {
      &.cblue_btn {
        a {
          color: #ffffff;

          &:hover {
            color: #6db1bc;
          }
        }
      }

      &.orange_btn {
        a {
          color: #ffffff;
          text-decoration: none;

          &:hover {
            color: #f08921;
          }
        }
      }

      &:hover {
        &.cblue_btn {
          a {
            color: #6db1bc;
          }
        }

        &.orange_btn {
          a {
            color: #f08921;
          }
        }
      }
    }
  }

  .environment_select_dropdown {
    margin-bottom: 15px;
    margin-top: 10px;

    .environment-select {
      max-width: 365px;
      width: 100%;
      background-color: #202020;
      color: #ffffff;
      height: 44px;
      background-image: none;
      // background-image: url("../../img/down_arrow_yellow.svg");
    }
  }

  .table_filter {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 5px;

    span {
      color: #909090;
      margin-right: 15px;
    }

    .form-check {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      padding: 0;
      margin: 0;
      margin-right: 15px;
    }

    .form-check-input {
      margin: 0;
      border-radius: 1px;
      width: 11px;
      height: 11px;

      &:focus {
        border: 1px solid #888888;
      }
    }

    .form-check-label {
      color: #909090;
    }
  }
}

.select-dropdown {
  background-color: transparent;
  border: 1px solid #202020;
  color: #888888;
}

table {
  tbody {
    tr {
      td {
        // .description {
        //   a {
        //     color: #6db1bf;
        //     text-decoration: none;

        //     &:hover {
        //       color: #ffffff;
        //     }
        //   }
        // }

        .filter {
          .form-check {
            display: inline-flex;
            align-items: center;
            padding: 0;
            margin: 0;
          }

          .form-check-input {
            margin: 1px 0 0 0;
            border-radius: 1px;
            width: 11px;
            height: 11px;

            &:focus {
              border: 1px solid #888888;
            }
          }
        }
      }
    }
  }
}

.store_listab {
  .nav-tabs {
    &.project_detail_tabs {
      .nav-item {
        padding: 8px 16px 0 !important;
        display: flex;
        align-items: flex-end !important;

        .nav-link {
          background: none;
          border: none;
          color: $secondary-color;
          font-size: $font14;
          padding: 0 0 8px;
          margin-bottom: -2px !important;
          overflow: hidden;
          position: relative;

          &.active {
            background: none;
            color: #ffffff;
            border-style: solid;
            border-image: none;
            border-color: #f08921;
            margin-bottom: -2px;
            border-radius: 2px;
            border: 0 !important;
            box-shadow: none !important;

            &::before {
              content: '';
              width: 100%;
              position: absolute;
              height: 5px;
              bottom: 0px;
              left: 0px;
              background-color: #f08921;
              filter: blur(3px);
              border-radius: 100%;
            }

            &::after {
              content: '';
              width: 100%;
              position: absolute;
              height: 3px;
              bottom: 0px;
              left: 0px;
              background-color: #f08921;
              border-radius: 3px;
            }
          }

          &:hover {
            color: #ffffff;
          }
        }

        // .nav-link {
        //   background: none;
        //   border: none;
        //   color: $secondary-color;
        //   font-size: $font14;
        //   padding: 0 0 8px;

        //   &.active {
        //     background: none;
        //     border: none;
        //     color: #ffffff;
        //     border-style: solid;
        //     border-top: 0;
        //     border-left: 0;
        //     border-right: 0;
        //     border-image: none;
        //     border-color: #f08921;
        //     border-width: 3px;
        //     margin-bottom: -2px;
        //     // padding: 0 0 8px;
        //     box-shadow: 0px -12px 5px -10px inset #f08921;
        //     border-radius: 2px;
        //   }

        //   &:hover {
        //     color: #ffffff;
        //   }
        // }
      }
    }
  }
}

.inner_table_select {
  position: relative;
  .form-select {
    max-width: 120px;
    min-width: 120px;
    width: 100%;
    background-color: #202020;
    color: #909090;
    height: 44px;
    background-image: url('../../img/inner_blue_dropdown_icon.svg');
    border: none;
    font-size: 14px;
    appearance: none;
    padding: 10px;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    &:focus {
      outline: none;
      // min-height: 180px;

      box-shadow: none;
      // background-color: #202020;
      // position: absolute;
      // max-width: 120px;
      // min-width: 120px;
      // border: 1px solid #909090;
    }

    & option {
      background-color: #202020;
      color: #909090;
      height: 30px;
      // text-align: center;
      width: 100%;
      // align-self: center;
      padding: 5px 10px;
      font-size: 14px;
      transition: background-color 0.3s ease;
    }

    & option:hover {
      background-color: #303030;
      color: #fff;
    }

    &.form-select2 {
      max-width: 120px;
      min-width: 120px;
      width: 100%;
      background-color: #202020;
      color: #909090;
      height: 44px;
      background-image: url('../../img/inner_blue_dropdown_icon.svg');
      border: none;
      font-size: 14px;
      appearance: none;
      padding: 10px;
      transition: background-color 0.3s ease, border-color 0.3s ease;

      &:focus {
        outline: none;
        min-height: 180px;
        background-image: none;
        box-shadow: none;
        background-color: #202020;
        position: absolute;
        max-width: 120px;
        min-width: 120px;
        border: 1px solid #909090;
        top: -20px;
        z-index: 9;
      }

      & option {
        background-color: #202020;
        color: #909090;
        height: 30px;

        width: 100%;
        align-self: center;
        padding: 5px 10px;
        font-size: 14px;
        transition: background-color 0.3s ease;
      }

      & option:hover {
        background-color: #303030;
        color: #fff;
      }
    }
  }
}

.filter_title {
  font-size: 16px;
  line-height: 24px;
  color: #909090;
}

.private_plugin_modal {
  .modal-dialog {
    margin: 28px auto;
    height: calc(100% - 56px);

    .modal-content {
      padding: 27px 74px 22px 67px;
      position: relative;

      @media only screen and (max-width: 1400px) {
        padding: 27px 40px 22px 30px;
      }

      .modal-header {
        border: none;
        padding-top: 0px;
        padding-bottom: 20px;

        h4 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 400;
          margin-bottom: 0px;
        }

        svg {
          font-size: 25px;
          line-height: 1;
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }

      .modal-body {
        .addproject {
          .upload {
            width: 100%;
            border-radius: 0;
            border: none;
            border-left: 1px solid #f28a03;
            height: 40px;
          }

          .file-btn {
            background-color: #f08921;
            color: #ffffff;
            border: 1px solid #f08921;
            border-radius: 7px;
            padding: 9px 22px;
            font-size: 14px;
            transition: all 0.2s ease-in;

            &:hover {
              background-color: rgba(240, 137, 33, 0.2);
              color: #f08921;
              border-radius: 7px;
            }
          }
        }
      }
    }
  }
}
