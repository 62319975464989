.primary_btn {
  background: linear-gradient($bluedark, $bluedark) padding-box,
    linear-gradient(to bottom right, $bluesecondary 0, $bluedark 33%, $bluedark 66%, $bluesecondary 100%) border-box;
  border-radius: 10px 0px 10px 0px;
  border: 1px solid transparent;
  color: $white;
  font-size: $font12;
  padding: 7px 0px;
  line-height: 1;
  width: 83px;
  font-weight: 500;
  font-size: $font14;
  &:hover {
    background: linear-gradient($blueprimary, $blueprimary) padding-box,
      linear-gradient(to bottom right, $bluesecondary 0, $bluedark 40%, $bluedark 0%, $bluesecondary 100%) border-box;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.secondary_btn {
  background: $secondary-bg;
  border-radius: 10px;
  border: 1px solid transparent;
  color: $secondary-color;
  font-size: $font14;
  padding: 7px 0px;
  cursor: pointer;
  width: 83px;
  line-height: 1;
  font-weight: 500;
  &:hover {
    @extend .primary_btn;
  }
}
.store_primary_btn {
  @extend .primary_btn;
  border-radius: 10px 0px 10px 0;
}
.orange_btn {
  background: $primary-color;
  color: $white;
  border-radius: 7px;
  border: none;
  padding: 9px 22px;
  font-size: $font14;
  transition: $transition-ease;
  border: 1px solid $primary-color;
  height: 40px;
  line-height: 1;
  &:hover {
    color: $primary-color;
    background-color: $primary-color-alpha;
    path {
      fill: black;
    }
  }
  &:disabled {
    background: $darkgray-alpha;
    color: $darkgray;
    border: 1px solid $darkgray;
    cursor: not-allowed;
  }
}
.grey_btn {
  background: $grey-color;
  color: $white;
  border-radius: 7px;
  border: none;
  padding: 9px 22px;
  font-size: $font14;
  transition: $transition-ease;
  &:hover {
    color: $primary-bg;
    background-color: $white;
    path {
      fill: black;
    }
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.green_btn {
  background: $green-color;
  color: $white;
  border-radius: 7px;
  border: none;
  padding: 9px 22px;
  font-size: $font14;
  transition: $transition-ease;
  &:hover {
    color: $primary-bg;
    background-color: $white;
    path {
      fill: black;
    }
  }
  &:disabled {
    background-color: grey;
    border: none;
    color: white;
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.red_btn {
  background: $red-color;
  color: $white;
  border-radius: 7px;
  border: none;
  padding: 9px 22px;
  font-size: $font14;
  transition: $transition-ease;
  &:hover {
    color: $primary-bg;
    background-color: $white;
    path {
      fill: black;
    }
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.cblue_btn_secondary {
  background-color: $primary-bg;
  color: $bluesecondary;
  border-radius: 7px;
  border: 1px solid $bluesecondary;
  padding: 9px 22px;
  font-size: $font14;
  transition: $transition-ease;
  line-height: 1;
  &:hover {
    background-color: $bluesecondary;
    border: 1px solid $bluesecondary;
    color: $white;
    border-radius: 7px;
    padding: 9px 22px;
    font-size: $font14;
    transition: $transition-ease;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
.cblue_btn {
  background-color: $bluesecondary;
  color: $white;
  border: 1px solid $bluesecondary;
  border-radius: 7px;
  padding: 9px 22px;
  font-size: $font14;
  transition: $transition-ease;
  height: 40px;
  line-height: 1;
  &:hover {
    background-color: $bluesecondary-alpha;
    color: $bluesecondary;
    border-radius: 7px;

    border: 1px solid $bluesecondary;
    padding: 9px 22px;
    font-size: $font14;
    transition: $transition-ease;
  }
  &:disabled {
    background: $darkgray-alpha;
    color: $darkgray;
    border: 1px solid $darkgray;
    cursor: not-allowed;
  }

  &.back_btn {
    background-color: $bluesecondary-alpha;
    color: $bluesecondary;
    border: 1px solid $bluesecondary;
    border-radius: 7px;
    padding: 9px 22px;
    font-size: $font14;
    transition: $transition-ease;
    height: 40px;
    span {
      color: $bluesecondary;
      transition: $transition-ease;
    }
    &:hover {
      background-color: $bluesecondary;
      color: $white;
      border-radius: 7px;
      border: 1px solid $bluesecondary;
      padding: 9px 22px;
      font-size: $font14;
      transition: $transition-ease;
      span {
        color: $white;
        transition: $transition-ease;
      }
    }
    &:disabled {
      background: $darkgray-alpha;
      color: $darkgray;
      border: 1px solid $darkgray;
      cursor: not-allowed;
    }
  }
}
.yellow_btn {
  background: linear-gradient($primary-color-dark, $primary-color-dark) padding-box,
    linear-gradient(
        to bottom right,
        $primary-color 0,
        $primary-color-dark 33%,
        $primary-color-dark 66%,
        $primary-color 100%
      )
      border-box;
  border-radius: 10px 0px 10px 0;
  border: 1px solid transparent;
  color: $white;
  font-size: $font12;
  padding: 7px 0px;
  line-height: 1;
  width: 83px;
  font-weight: 500;
  &:hover {
    background: linear-gradient($primary-color, $primary-color) padding-box,
      linear-gradient(
          to bottom right,
          $primary-color 0,
          $primary-color-dark 40%,
          $primary-color-dark 0%,
          $primary-color 100%
        )
        border-box;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.border-b {
  border-color: $primary-color !important;
}

.tab_btn {
  transition: 0.1s ease-in-out;
  background: $primary-color-alpha;
  border: 1px solid $primary-color;
  border-bottom: 0;
  border-radius: 7px 7px 0px 0px;
  color: $primary-color;
  font-size: $font14;
  padding: 9px 22px;
  line-height: 1;
  width: auto;
  font-weight: 500;
  &:hover:not(.active) {
    background: $primary-color;
    color: $white;
  }
}

.tab_btn.active {
  background: $primary-color;
  color: $white;
}

.filter-btn-inactive {
  background: $secondary-bg;
  border-radius: 10px;
  border: 1px solid transparent;
  color: $secondary-color;
  font-size: $font14;
  padding: 7px 0px;
  cursor: pointer;
  width: 83px;
  line-height: 1;
  font-weight: 500;
  &:hover {
    background: $bluedark;
    border: 1px solid $blueprimary;
    color: $white;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.filter-btn-active {
  border-radius: 10px;
  font-size: $font14;
  padding: 7px 0px;
  cursor: pointer;
  width: 83px;
  line-height: 1;
  font-weight: 500;
  background: $bluedark;
  border: 1px solid $blueprimary;
  color: $white;
}

.switch {
  height: 25px !important;
  width: 50px !important;
}

.slider:before {
  height: 17px !important;
  width: 17px !important;
}
