@import 'variables';
@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-SemiBold.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-Bold.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-ExtraBold.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-Black.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-Thin.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-ExtraLight.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-Light.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-Regular.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Barlow';
  src: url('#{$Barlow-font-path}/Barlow-Medium.woff2') format('woff2'),
    url('#{$Barlow-font-path}/Barlow-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.font-family-current {
  font-family: $font-family;
}
.primary-color {
  color: $primary-color;
}
.secondary-color {
  color: $secondary-color;
}
.grey-color {
  color: $grey-color;
}
.primary-bg {
  background-color: $primary-bg;
}
.secondary-bg {
  background-color: $secondary-bg;
}
.blueprimary {
  color: $blueprimary;
}
.bluesecondary {
  color: $bluesecondary;
}
.bluedark {
  color: $bluedark;
}
.darkgray {
  color: $darkgray;
}
.font36 {
  font-size: $font36;
}
.font30 {
  font-size: $font30;
}
.font20 {
  font-size: $font20;
}
.font14 {
  font-size: $font14;
}
.font12 {
  font-size: $font12;
}
.font10 {
  font-size: $font20;
}

h2 {
  color: $white;
  font-size: 20px;
  line-height: 30px;
}

p {
  color: $darkgray;
  font-size: 16px;
  line-height: 24px;
}
