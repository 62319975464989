.home_main {
  max-width: 730px;
  margin: 0 auto;
  padding-bottom: 50px;
  .home_log {
    margin-top: 65px;
    margin-bottom: 65px;
    text-align: center;
    img {
      max-height: 85px;
    }
  }
  .home_title {
    color: white;
    text-align: center;
    + p {
      text-align: center;
      color: $secondary-color;
      margin-bottom: 20px;
    }
  }
  .linkdata {
    margin-bottom: 30px;
    .listgrid {
      background-color: $secondary-bg;
      border-radius: 5px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 100%;
      transition: $transition-ease;
      cursor: pointer;
      .list_title {
        h2 {
          font-size: $font20;
          font-weight: 500;
        }
        .listdesc {
          color: $secondary-color;
          font-size: $font14;
          margin-top: 10px;
          margin-bottom: 0px;
        }
        img {
          max-height: 30px;
        }
      }
      .list_icn {
        svg {
          font-size: 30px;
          cursor: pointer;
          path {
            fill: $primary-bg;
            cursor: pointer;
          }
        }
      }
      .disabled {
        background-color: #141414;
        pointer-events: none;
        opacity: 0.5;
        cursor: default;
        div {
          font-size: $font20;
          font-weight: 500;
        }
        &:hover {
          background-color: #1a1a1a;
          .list_icn svg path {
            -webkit-transition: $transition-ease;
            -o-transition: $transition-ease;
            transition: $transition-ease;
            fill: $primary-color;
          }
        }
      }
      &:hover {
        background-color: $third-bg;
        .list_icn svg path {
          -webkit-transition: $transition-ease;
          -o-transition: $transition-ease;
          transition: $transition-ease;
          fill: $primary-color;
        }
      }
      &:hover,
      &:focus {
        text-decoration: none;
      }
      @keyframes pulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
