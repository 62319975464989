.input-group {
  min-height: 40px;

  input::placeholder {
    color: $darkgray;
  }

  input,
  select {
    color: $white;
    border-radius: 0px 8px 8px 0px !important;
  }

  select option {
    background-color: $primary-bg;
  }
}

.input-group .form-check-group .form-check-input {
  border-radius: 0px !important;
}

.input-group-text {
  border-radius: 8px 0px 0px 8px;
}

.input-group-text-create-project {
  border-radius: 0px 8px 8px 0px;
}

textarea.form-control {
  border-radius: 0px 8px 8px 0px !important;
}

.form-select option {
  background-color: $primary-bg;
}

.form-control {
  border-radius: 8px 8px 8px 8px !important;
}

.form-control.form-square {
  border-radius: 0 !important;
}

.file-upload {
  background: none !important;
  color: $darkgray !important;
  border: 1px solid $third-color !important;
}

// .input-group {
//   min-height: 40px;

//   input::placeholder {
//     color: $darkgray;
//   }

//   input,
//   select {
//     color: $white;
//     // border-radius: 0px 0px 20px 0px!important;
//   }

//   select option {
//     background-color: $primary-bg;
//   }
// }

// .input-group-text{
//     border-radius: 20px 0px 0px 0px;
// }

// .input-group-text-create-project {
//   border-radius: 0px 0px 0px 0px;
// }

// .form-select option {
//   background-color: $primary-bg;
// }

// .form-control{
//     border-radius: 0px 0px 20px 0px!important;
// }

// .form-control.form-square {
//   border-radius: 0 !important;
// }

// .file-upload {
//   background: none !important;
//   color: $darkgray !important;
//   border: 1px solid $third-color !important;
// }
