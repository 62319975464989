.store-queue {
  .accordion_row {
    .accordion {
      .accordion-item {
        thead {
          background-color: #404041;
          height: 98px;
          tr {
            &.accordion-header {
              .accordion_button_arrow {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .accordion_arrow {
                  margin: 0 32px;
                  transform: rotate(0deg);
                  transition: transform 0.2s ease-in-out;
                  background-image: url(../../img/accordian_arrow.svg);
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  width: 30px;
                  height: 12px;
                  cursor: pointer;
                  @media only screen and (max-width: 1024px) {
                    margin: 0 0 0 32px;
                  }
                  &.collapsed {
                    transform: rotate(-180deg);
                    transition: transform 0.2s ease-in-out;
                  }
                }
              }
            }
            th {
              border: 0px;
              color: #fff;
              font-size: 16px;
              font-weight: 500;
              line-height: 1;

              .project_name {
                color: #f28a03;
                font-size: 18px;
                font-weight: 600;
                padding-left: 30px;
                letter-spacing: 0.5px;
              }
            }
          }
        }

        tbody {
          tr {
            position: relative;

            &::after {
              height: 0% !important;
            }

            &:first-child {
              &::after {
                position: absolute;
                content: '';
                width: 1px;
                height: 50% !important;
                background-color: #505050;
                top: 0px;
                left: 15px;
                bottom: 0px;
                right: 0px;
              }
            }

            &:nth-child(n) {
              background-color: #202020;
            }

            &:nth-child(2n) {
              background-color: #2b2b2b;
            }

            &:last-child {
              background-color: transparent;
            }

            td {
              color: #909090;
              border: 0px;

              .project_stage_title {
                padding-left: 28px;
                font-size: 16px;
                position: relative;
                text-transform: capitalize;
                white-space: break-spaces;
                word-break: break-word;
                color: #fff;

                &::after {
                  position: absolute;
                  content: '';
                  width: 20px;
                  height: 1px;
                  background-color: #505050;
                  top: 50%;
                  transform: translate(0%, -50%);
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                }

                &:hover {
                  color: #fff;
                }
              }

              .red_btn {
                padding: 6px 32px;
                min-width: 176px;
                justify-content: center;
              }

              .cblue_btn {
                min-width: 176px;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

.storequeue_search_btn {
  .environment-select {
    max-width: 365px;
    width: 100%;
    background-color: #202020;
    color: #ffffff;
    height: 44px;
  }
}
