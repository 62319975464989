.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;

  aside.sidebar {
    // -ms-flex-preferred-size: 250px;
    //     flex-basis: 250px;
    // min-width: 250px;
    z-index: 2;
    border-right: 1px solid $primary-bg;
    background-color: $secondary-bg;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;

    .first-aside {
      background-color: $secondary-bg;
      min-width: 250px;

      #sideaccordion {
        border-top: 2px solid $primary-bg;

        .accordion-item {
          background-color: $third-bg;
          border: none;

          .accordion-header:not(:last-child) {
            background-color: $secondary-bg;
          }

          .accordion-button {
            background-color: $secondary-bg;
            color: #ffffff;
            font-size: $font14;
            border: none;
            border-bottom: 2px solid $primary-bg;
            padding-left: 0;
            padding-right: 0;
            -webkit-box-shadow: none;
            box-shadow: none;

            &:focus {
              -webkit-box-shadow: none;
              box-shadow: none;
            }

            &:not(.collapsed)::after,
            &::after {
              background-image: $accordion-button-icon;
              width: 13px;
              height: 13px;
              background-size: contain;
              transition: none;
            }

            &:hover {
              color: $primary-color;
            }
          }

          &:last-of-type {
            border-bottom: none;
          }

          .accordion-collapse {
            border-radius: 0%;
            max-height: 200px;
            overflow-y: auto;

            .accordion-body {
              padding-left: 0%;
              padding-right: 0%;

              .filter {
                .ck_main {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  gap: 8px;
                  cursor: pointer;

                  &:hover label {
                    color: $white;
                  }

                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }

                  .form-check-input {
                    cursor: pointer;
                  }

                  label {
                    color: $secondary-color;
                    font-size: $font14;
                    cursor: pointer;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    transition: $transition-ease;
                  }
                }
              }

              ul {
                list-style: none;
                padding: 0%;
                margin-bottom: 0%;

                li a {
                  font-size: $font14;
                  color: #ffffff;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                  justify-content: space-between;
                  text-decoration: none;
                  margin-bottom: 10px;
                  color: $secondary-color;
                  transition: $transition-ease;

                  &:hover {
                    color: $white;
                  }
                }
              }

              .pricebox {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                .price_divider {
                  color: #ffffff;
                  padding: 0 10px;
                }

                .mintext,
                .maxtext {
                  font-size: $font14;
                  color: #ffffff;
                  position: relative;

                  input {
                    width: 54px;
                    background-color: $primary-bg;
                    border: none;
                    border-radius: 5px;
                    height: 28px;
                    color: #ffffff;
                    padding-left: 15px;
                    font-size: $font14;

                    &:focus {
                      outline: none;
                      -webkit-box-shadow: none;
                      box-shadow: none;
                    }
                  }

                  span {
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    left: 5px;
                    font-size: $font14;
                  }
                }
              }
            }
          }
        }
      }

      .logo {
        padding-top: 17px;
        border-bottom: 2px solid $primary-bg;
        padding-bottom: 15px;
      }

      .search {
        .search_main {
          position: relative;

          input {
            width: 100%;
            border: none;
            background: $primary-bg;
            height: 33px;
            padding: 0 9px;
            color: #ffffff;
            font-size: $font14;
            border-radius: 6px;

            &:focus {
              outline: none;

              &::placeholder {
                color: transparent;
              }
            }
          }

          svg {
            color: $third-color;
            position: absolute;
            right: 9px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }

    .second-aside {
      background-color: $primary-bg;
      border-right: 1px solid $secondary-bg;
      width: 60px;
      padding: 17px 12px 0 12px;
      text-align: center;

      .socond_menu ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 35px;
        }
      }

      .socond_menu {
        margin-top: 24px;
      }
    }
  }
}

a {
  &.menu-item {
    line-height: 1;
    display: inline-flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      line-height: 1;
    }
  }
}

.sidebar {
  li {
    .menu-item {
      &::after {
        display: none;
      }

      svg {
        width: 20px;
      }

      .menuText {
        color: gray;
      }
    }
  }

  .collapseToggleIcon,
  .collapseToggleIconOpen {
    background-color: #202020 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    box-sizing: content-box !important;
    box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 0.2);
    border-radius: 5px;

    &:hover {
      box-shadow: 0px 0px 5px 2px rgba(255, 255, 255, 1);

      svg {
        color: #ffffff !important;
        filter: drop-shadow(0px 0px 7px #ffffff);
      }
    }

    svg {
      width: 13px !important;
      height: 13px !important;
      transition: all 0.16s ease-in-out;
    }
  }
}

.sidebar .menu-item.active svg,
.sidebar .menu-item:hover svg,
.sidebar li:has(.menuText:hover) svg,
.sidebar .menu-item:hover .menuText,
.sidebar .menu-item.active .menuText,
.sidebar li svg:hover + .menuText,
.sidebar li svg:hover,
.sidebar li .menuText:hover {
  color: white !important;
  filter: drop-shadow(0px 0px 7px #ffffff);
}

.sidebar .fa-circle-question {
  margin: 0 !important;
}
