.product_inner {
  padding: 36px 0 81px;
  max-width: 939px;
  margin: 0 auto;

  .product_banner {
    position: relative;
    margin-bottom: 21px;

    .product_img {
      img {
        width: 100%;
        height: auto;
        max-height: 304px;
        object-fit: cover;
        object-position: center;
      }
    }

    .product_like {
      position: absolute;
      right: 12px;
      top: 12px;
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #68686880;
      border-radius: 25px;
    }
  }

  .product_title_price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 -15px 47px;

    .product_card {
      flex: 0 0 158px;
      max-width: 158px;
      padding: 0 15px;
    }

    .product_title {
      flex: 0 0 calc(100% - 340px);
      max-width: calc(100% - 340px);
      padding: 0 15px;

      h3 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 0px;
        color: #ffffff;
      }

      p,
      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0px;
        color: #909090;
      }
    }

    .product_price {
      flex: 0 0 152px;
      max-width: 152px;
      padding: 0 15px;
      text-align: end;

      p.main_price {
        color: #606060;
        font-size: 26px;
        line-height: 39px;
        font-weight: 500;
        text-decoration: line-through;
        margin-bottom: 0px;
      }

      p.final_price {
        color: #f28a03;
        font-size: 36px;
        line-height: 54px;
        font-weight: 500;
        margin-bottom: 0px;
      }
    }
  }

  .product_dec_data {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -14px;

    .product_dec {
      flex: 0 0 calc(100% - 400px);
      max-width: calc(100% - 400px);
      padding: 0 14px;

      h4 {
        color: #ffffff;
        padding-bottom: 5px;
        border-bottom: 1px solid #404040;
        font-weight: 700;
        font-size: 22px;
        line-height: 33px;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
      }
    }

    .product_ss_data {
      flex: 0 0 400px;
      max-width: 400px;
      padding: 0 14px;

      .product_ss {
        margin-bottom: 27px;

        .product_ss_title {
          padding-bottom: 5px;
          border-bottom: 1px solid #404040;
          display: flex;
          align-items: center;
          justify-content: space-between;
          grid-area: 15px;
          margin-bottom: 10px;

          h4 {
            color: #ffffff;
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
            margin-bottom: 0px;
          }

          a {
            color: #f28a03;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
              rotate: 270deg;
              width: 16px;
              height: 6px;
              object-fit: contain;
            }
          }
        }

        .ss_list {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          img {
            width: 89px;
            height: 89px;
            object-fit: cover;
          }
        }
      }

      .product_detail {
        background-color: #202020;
        padding: 36px;
        margin-bottom: 50px;

        .product_detail_inner {
          .product_detail_row {
            border-bottom: 1px solid #101010;
            padding: 9px 0px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 15px;

            &:last-child {
              border-bottom: 0px;
              padding: 9px 0px 0px;

              .data_result {
                span {
                  margin: 0px 9px 6px 0px;
                  background-color: #101010;
                  padding: 6px 12px 8px;
                  text-decoration: underline;
                  border-radius: 5px;
                }
              }
            }

            .data_title {
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              margin-bottom: 0px;
            }

            .data_result {
              font-size: 16px;
              line-height: 24px;
              color: #f28a03;
              margin-bottom: 0px;
            }
          }
        }
      }

      .product_rating {
        .product_rating_title {
          padding-bottom: 5px;
          border-bottom: 1px solid #404040;
          display: flex;
          align-items: center;
          justify-content: space-between;
          grid-area: 15px;
          margin-bottom: 10px;

          h4 {
            color: #ffffff;
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
            margin-bottom: 0px;
          }

          a {
            color: #f28a03;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 5px;

            svg {
              rotate: 270deg;
              width: 16px;
              height: 6px;
              object-fit: contain;
            }
          }
        }

        .product_reviews {
          margin-bottom: 22px;

          .reviews_word {
            p {
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              font-weight: 400;
              margin-bottom: 9px;
            }
          }

          .reviews_star {
          }
        }

        .product_star_progress {
          .star_progress_inner {
            .star_progress_row {
              display: flex;
              align-items: center;
              margin: 0 -7px 6px;

              .star_count {
                padding: 0 7px;
                flex: 0 0 55px;
                max-width: 55px;

                p {
                  margin-bottom: 0px;
                  color: #606060;
                  font-size: 16px;
                  line-height: 24px;
                }
              }

              .progress_bar {
                padding: 0 7px;
                flex: 0 0 260px;
                max-width: 260px;

                .progress {
                  background-color: #404040;
                  border-radius: 1px;
                  height: 20px;

                  .progress-bar {
                    background-color: #f28a03;
                  }
                }
              }

              .star_given_count {
                padding: 0 7px;
                flex: 0 0 calc(100% - 315px);
                max-width: calc(100% - 315px);

                p {
                  margin-bottom: 0px;
                  color: #606060;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }
        }

        .back_to_login {
          a {
            font-size: 16px;
            line-height: 24px;
            color: #f28a03;
            text-decoration: none;
          }
        }
      }
    }
  }
}
