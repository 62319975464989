.composer-content,
.composer-content:focus {
  background: #202020;
  color: #909090;
  border: 1px solid #505050;
  box-shadow: none;
  outline: none;
}
.form-floating {
  position: relative;
  svg {
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 10px;
    path {
      fill: #6db1bc;
    }
  }
}
