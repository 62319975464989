#terminal {
  display: block;
}


.terminaldivbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1e1e1e;
  color: #00ff00;
  font-family: monospace;
}

#open-terminal-button {
  padding: 10px 20px;
  background-color: #2b2b2b;
  color: #f08921;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

#open-terminal-button i {
  margin-right: 8px;
}

.composer-model {
  .modal-dialog {
    .modal-content {
      .terminal {
        background-color: #000000;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
        position: relative;
        z-index: 1000;

        .terminal-header {
          display: flex;
          padding: 8px;
          background-color: #333333;
          color: #ffffff;
          justify-content: flex-end;

          .close {
            color: #ff605c;
            font-size: 16px;
            cursor: pointer;
            position: relative;
            width: auto;
            top: 0px;
            right: 5px;
            background-color: transparent;
            border-color: transparent;
          }
        }

        .terminal-content {
          padding: 20px;
          font-size: 16px;
          line-height: 1.5;

          .line {
            display: flex;
            align-items: center;

            span {
              width: auto;
              background-color: transparent;
              border-color: transparent;

              &.prompt {
                color: #f08921;
              }

              &.command {
                color: #ffffff;
                margin-left: 5px;
              }

              &.cursor {
                width: 10px;
                height: 18px;
                background-color: #f08921;
                margin-left: 5px;
                animation: blink 1s infinite;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes blink {

  0%,
  50% {
    opacity: 1;
  }

  51%,
  100% {
    opacity: 0;
  }
}
