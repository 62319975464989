.document_listab {
  .nav-tabs {
    border-bottom: 2px solid $secondary-bg;

    .nav-item {
      padding: 8px 16px 0 !important;

      .nav-link {
        background: none;
        border: none;
        color: $secondary-color;
        font-size: $font14;
        padding: 0 0 8px;

        &.active {
          background: none;
          border: none;
          color: $white;
          border-style: solid;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-image: none;
          border-color: $primary-color;
          border-width: 3px;
          margin-bottom: -2px;
          box-shadow: 0px -12px 5px -10px inset #f08921;
          border-radius: 2px;
          // padding: 0 0 8px;
        }

        &:hover {
          color: $white;
        }
      }
    }
  }

  .documentation_main {
    .documentation_inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 35px;
      gap: 58px;

      .document_left {
        flex: 0 0 280px;
        max-width: 280px;

        .document_menu {
          padding: 30px;
          height: calc(100vh - 135px);
          background-color: $secondary-bg;
          overflow: hidden scroll;

          &::-webkit-scrollbar {
            display: none;
          }

          .search {
            .search_main {
              input {
                width: 220px;
                background-color: $primary-bg;
              }

              svg {
                path {
                  fill: $bluesecondary;
                }
              }
            }
          }

          .first_level {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
              color: $darkgray;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 16px;

              .second_level {
                margin-top: 7px;
                padding: 0px;
                list-style-type: none;

                li {
                  color: $darkgray;
                  font-size: 14px;
                  line-height: 21px;
                  margin-bottom: 0px;
                  padding: 7px 0px 0px 25px;
                  position: relative;

                  &:last-child {
                    &::after {
                      height: 62%;
                    }
                  }

                  &::after {
                    position: absolute;
                    left: 7px;
                    top: 0px;
                    content: '';
                    background-color: $darkgray;
                    width: 1px;
                    height: 100%;
                  }

                  &::before {
                    position: absolute;
                    left: 7px;
                    top: 62%;
                    transform: translate(0px, -50%);
                    content: '';
                    background-color: $darkgray;
                    width: 10px;
                    height: 1px;
                  }
                }
              }
            }
          }
        }
      }

      .document_right {
        flex: 0 0 calc(100% - 338px);
        max-width: calc(100% - 338px);
        overflow: hidden scroll;
        height: calc(100vh - 180px);

        &::-webkit-scrollbar {
          display: none;
        }

        .introduction {
          margin-bottom: 52px;

          .introduction_inner {
            h1 {
              margin-bottom: 16px;
            }

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 4px;
              color: $darkgray;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .Workflows {
          padding: 54px 0px 60px;
          border-top: 1px solid $secondary-bg;

          .Workflows_inner {
            h2 {
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 16px;
              color: $white;
            }

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 16px;
              color: $darkgray;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .Workflows_blocks {
              margin: 32px 0px 0 0px;
              display: flex;
              align-items: stretch;
              flex-wrap: wrap;
              // gap: 28px;
              justify-content: space-between;

              .Workflows_block {
                padding: 40px;
                border: 1px solid $third-color;
                border-radius: 8px;
                flex: 0 0 32%;
                max-width: 32%;
                margin-right: 1.871%;

                &:last-child {
                  margin-right: 0;
                }

                h3 {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  margin-bottom: 8px;
                  color: $white;
                }

                p {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  margin-bottom: 4px;
                  color: $darkgray;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        .api_section {
          .api_inner {
            padding: 20px;
            border: 1px solid $third-color;
            border-radius: 8px;
            position: relative;

            a {
              position: absolute;
              top: 20px;
              right: 20px;
              width: 19px;
              height: 22px;
            }

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 4px;
              color: $darkgray;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .roadmap_main {
    padding: 42px 0px 48px;

    .roadmap_inner {
      display: flex;
      flex-wrap: wrap;
      row-gap: 68px;

      .roadmap_coming_soon {
        flex: 0 0 50%;
        max-width: 600px;
        margin-right: 12.262%;

        h2 {
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid $secondary-bg;
        }

        .coming_soon_detail {
          .coming_soon_list {
            list-style-type: none;
            padding: 0 0 0 20px;

            li {
              color: $darkgray;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 37px;
              display: flex;
              align-items: center;
              position: relative;

              &::after {
                content: '';
                position: absolute;
                width: 7px;
                height: 7px;
                border-radius: 100%;
                background-color: $primary-color;
                top: 50%;
                transform: translate(0, -50%);
                left: -20px;
              }

              .coming_soon_tag {
                color: $white;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                padding: 8px 19px;
                background-color: $green-tag;
                border-radius: 8px;
                margin-left: 48px;
              }
            }
          }
        }
      }

      .roadmap_updates {
        flex: 0 0 50%;
        max-width: 600px;
        margin-right: 12.262%;

        h2 {
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid $secondary-bg;
        }

        .accordion {
          .accordion-item {
            border-bottom: 1px solid $secondary-bg;
            background-color: $primary-bg;

            .accordion-header {
              .accordion-button {
                background-color: $primary-bg;
                position: relative;
                border: none;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 25px;
                color: $white;
                padding: 26px 20px 22px;

                &:focus {
                  background-color: $primary-bg;
                  border: none;
                  box-shadow: none;
                  outline: none;
                }

                &::after {
                  position: absolute;
                  background-image: url(../../img/plus-circle.svg);
                  background-repeat: no-repeat;
                  background-size: cover;
                  width: 12px;
                  height: 12px;
                  left: 0;
                  right: auto;
                }

                span {
                  &.day_date {
                    color: $darkgray;
                  }
                }
              }
            }

            .accordion-collapse {
              .accordion-body {
                padding: 0px 22px 20px;

                p {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }

      .roadmap_completed {
        flex: 0 0 50%;
        max-width: 600px;
        margin-right: 12.262%;

        h2 {
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid $secondary-bg;
        }

        .completed_detail {
          .completed_list {
            list-style-type: none;
            padding: 0 0 0 20px;

            li {
              color: $darkgray;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 37px;
              display: flex;
              align-items: center;
              position: relative;
              text-decoration: line-through;

              &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background-image: url(../../img/checkmark-circle.svg);
                background-repeat: no-repeat;
                background-size: cover;
                top: 50%;
                transform: translate(0, -50%);
                left: -20px;
              }
            }
          }
        }
      }
    }
  }
}
