.search {
  .search_main {
    position: relative;

    input {
      width: 250px;
      border: none;
      background: $secondary-bg;
      height: 40px;
      padding: 0 9px;
      color: #ffffff;
      font-size: $font14;
      border-radius: 6px;

      &:focus {
        outline: none;
      }
    }

    svg {
      position: absolute;
      right: 9px;
      max-height: 16px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.project-dropdown {
  line-height: 1;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: auto;
  border: none;

  &::after {
    content: none;
  }

  span {
    background: white;
    width: 3px;
    height: 3px;
    display: inline-block;
    margin: 2px;
    border-radius: 50%;
    line-height: 1;
  }
}

.dropdown-menu.show {
  background: $secondary-bg;
}

.dropdown-item {
  color: $secondary-color;
  font-size: $font12;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: $secondary-bg;
  background-color: $secondary-color;
}

.environment-select {
  background-color: transparent;
  color: $secondary-color;
  font-size: $font14;
  border: 1px solid $secondary-bg;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $secondary-bg;
  }
}

.authorcolor {
  color: $bluesecondary;
}

.composer-model {
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 56px);

    .modal-header {
      // border-bottom: 1px solid #909090
      border: none;
      padding-top: 0px;
      padding-bottom: 20px;

      h4 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        margin-bottom: 0px;
      }

      svg {
        font-size: 25px;
        line-height: 1;
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }

    .modal-footer {
      border: none;
    }

    .modal-content {
      background-color: #101010;

      span {
        width: 170px;
        font-size: 14px;
        background-color: #2b2b2b;
        border: 1px solid #505050;
        color: white;
        white-space: break-spaces;
        word-break: break-word;
      }

      select {
        background-color: transparent;
        font-size: 14px;
        border: 1px solid #505050;
        color: #909090;
      }
    }

    .modal-content {
      .modal-body {
        padding: 0px;

        ul {
          padding: 16px 16px 5px;

          &.nav-pills {
            border-bottom: 1px solid $secondary-bg;

            .nav-item {
              padding: 0 20px;

              .nav-link {
                font-size: 16px;
                line-height: 24px;
                color: #909090;
                padding: 0;
                position: relative;

                &:hover {
                  color: $primary-color;
                  background-color: transparent;

                  &::after {
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    content: '';
                    background-color: $primary-color;
                    width: 100%;
                    height: 3px;
                  }
                }

                &.active {
                  color: $primary-color;
                  background-color: transparent;

                  &::after {
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    content: '';
                    background-color: $primary-color;
                    width: 100%;
                    height: 3px;
                  }
                }
              }
            }
          }
        }

        .tab-content {
          padding: 0 16px;

          .tab-pane {
            &#pills-wp {
              margin-top: 30px;
            }

            &:not(#pills-wp) {
              margin-top: 43px;
            }

            &#pills-composer {
              .addproject {
                .upload {
                  width: 100%;
                  border-color: #f28a03;
                  border-width: 0 0 0 1px;
                  border-style: solid;
                }
              }

              .login_register {
                margin-bottom: 32px;

                span {
                  width: auto;
                  background-color: transparent;
                  border: none;
                }

                .switch {
                  height: 12px !important;
                  width: 26px !important;

                  input {
                    &:checked {
                      + .slider {
                        &::before {
                          transform: translate(76%, 10%);
                        }
                      }
                    }
                  }

                  .slider {
                    &::before {
                      left: 0;
                      bottom: 0;
                      height: 16px !important;
                      width: 16px !important;
                      transform: translate(-12%, 10%);
                    }
                  }
                }
              }

              .file-btn {
                background-color: #6db1bc;
                color: #ffffff;
                border: 1px solid #6db1bc;
                border-radius: 7px;
                padding: 9px 22px;
                font-size: 14px;
                transition: all 0.2s ease-in;

                &:hover {
                  background-color: rgba(109, 177, 188, 0.2);
                  color: #6db1bc;
                  border-radius: 7px;
                }

                label {
                  cursor: pointer;
                }
              }
            }

            &#pills-ftp {
              .addproject {
                .file-btn {
                  background-color: $primary-color;
                  border: 1px solid $primary-color;
                  border-radius: 7px;
                  transition: all 0.2s ease-in;

                  &:hover {
                    color: $primary-color;
                    background-color: rgba(240, 137, 33, 0.2);
                  }

                  label {
                    cursor: pointer;
                  }
                }

                .input-group-text {
                  width: 250px;
                }

                .upload {
                  width: 100%;
                  border-color: #f28a03;
                  border-width: 0 0 0 1px;
                  border-style: solid;
                }
              }
            }

            &#pills-ssh {
              .addproject {
                .file-btn {
                  background-color: $primary-color;
                  border: 1px solid $primary-color;
                  border-radius: 7px;
                  transition: all 0.2s ease-in;

                  &:hover {
                    color: $primary-color;
                    background-color: rgba(240, 137, 33, 0.2);
                  }

                  label {
                    cursor: pointer;
                  }
                }

                .input-group-text {
                  width: 250px;
                }

                .upload {
                  width: 100%;
                  border-color: #f28a03;
                  border-width: 0 0 0 1px;
                  border-style: solid;
                }
              }
            }

            .addproject {
              .input-group {
                margin-bottom: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}

.description {
  width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-word;
}

.accordion_row {
  .accordion {
    border: none;

    .accordion-item {
      border: none;
      border-radius: 0px;

      // .btne1 {
      .search_btn1 {
        color: white;
        background-color: #f08921;
        padding: 9px;
        border: none;
        border-radius: 10px;
        margin: 2px;
        font-size: 14px;

        &:hover {
          color: #f08921;
          opacity: 0.6;
          border: 1px solid #f08921;
          // padding:4px;
          background-color: rgba(240, 137, 33, 0.2);
        }
      }

      // }
      .accordion-collapse {
        .table {
          thead {
            th {
              white-space: break-spaces !important;
              word-break: break-word;
            }
          }

          tbody {
            tr {
              position: relative;

              &::after {
                position: absolute;
                content: '';
                width: 1px;
                height: 100%;
                background-color: #202020;
                top: 0px;
                left: 15px;
                bottom: 0px;
                right: 0px;
              }

              &:nth-last-child(2) {
                &::after {
                  height: 50%;
                }
              }

              &:last-of-type {
                &::after {
                  height: 0%;
                }
              }

              td {
                .project_stage_title {
                  padding-left: 28px;
                  position: relative;
                  text-transform: capitalize;
                  white-space: break-spaces;
                  word-break: break-word;
                  color: #686868;

                  &::after {
                    position: absolute;
                    content: '';
                    width: 20px;
                    height: 1px;
                    background-color: #202020;
                    top: 50%;
                    transform: translate(0%, -50%);
                    left: 0px;
                    bottom: 0px;
                    right: 0px;
                  }

                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }

    .accordion-button {
      border: none;
      border-radius: 0px;
      padding: 0px;
      margin: 0;
      box-shadow: none;
      display: table;
      position: relative;
      background-color: #202020;

      &.collapsed {
        border-radius: 0px;
      }

      &:not(.collapsed)::after {
        transform: translate(0px, -50%) rotate(180deg);
      }

      &.collapsed::after {
        transform: translate(0px, -50%) rotate(0deg);
      }

      &::after {
        display: none;
      }

      &.table {
        tbody {
          tr {
            td {
              border: 0px;

              .project_name_icon {
                display: flex;
                // flex-wrap: wrap;
                align-items: center;
                gap: 16px;

                .project_name {
                  font-size: 16px;
                  color: #fff;
                  font-weight: 500;
                  display: inline-block;
                  position: relative;
                  white-space: break-spaces;
                }

                .project_icon {
                  line-height: 1;
                }
              }

              .accordion_button_arrow {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .accordion_arrow {
                  margin: 0 32px;
                  transform: rotate(0deg);
                  transition: transform 0.2s ease-in-out;
                  background-image: url(../../img/accordian_arrow.svg);
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  width: 30px;
                  height: 12px;

                  @media only screen and (max-width: 1024px) {
                    margin: 0 0 0 32px;
                  }

                  &.collapsed {
                    transform: rotate(-180deg);
                    transition: transform 0.2s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.projectlist {
  position: relative;

  .project_icon {
    svg {
      transition: 0.15s ease-in-out;
    }

    &:hover {
      .fa-pen {
        cursor: pointer;
        color: white;
        transition: 0.16s ease-in-out;
        filter: drop-shadow(0px 0px 5px silver) !important;
      }

      .fa-trash {
        cursor: pointer;
        color: red !important;
        transition: 0.16s ease-in-out;
        filter: drop-shadow(0px 0px 5px red) !important;
      }

      .fa-timeline {
        cursor: pointer;
        color: #fff;
        filter: drop-shadow(0px 0px 5px #fff);
      }
    }
  }

  .gp_cp_btn {
    position: absolute;
    right: 0;
    top: 20px;
  }
}

table {
  thead {
    tr {
      th {
        &:first-child {
          width: 300px;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-last-of-type(2) {
        td {
          .project_stage_title {
            &::before {
              height: 36px;
            }
          }
        }
      }

      td {
        &:first-child {
          width: 300px;
        }
      }
    }
  }
}

.store_list_search {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
  position: relative;

  .store_list {
    // flex: 0 0 calc(100% - 275px);
    // max-width: calc(100% - 275px);
    flex: 0 0 100%;
    max-width: 100%;

    .nav-tabs {
      border-bottom: 2px solid $secondary-bg;

      &.projects-first {
        .nav-item {
          padding: 8px 16px 0 !important;
          display: flex;
          align-items: flex-end !important;

          .nav-link {
            background: none;
            border: none;
            color: $secondary-color;
            font-size: $font14;
            padding: 0 0 8px;
            margin-bottom: -2px !important;
            overflow: hidden;
            position: relative;

            &.active {
              background: none;
              color: #ffffff;
              border-style: solid;
              border-image: none;
              border-color: #f08921;
              margin-bottom: -2px;
              border-radius: 2px;
              border: 0 !important;
              box-shadow: none !important;

              &::before {
                content: '';
                width: 100%;
                position: absolute;
                height: 5px;
                bottom: 0px;
                left: 0px;
                background-color: #f08921;
                filter: blur(3px);
                border-radius: 100%;
              }

              &::after {
                content: '';
                width: 100%;
                position: absolute;
                height: 3px;
                bottom: 0px;
                left: 0px;
                background-color: #f08921;
                border-radius: 3px;
              }
            }

            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .store_search {
    flex: 0 0 250px;
    max-width: 250px;
    position: absolute;
    // top: -120%;
    // right: 150px;
    top: -12px;
    right: 0px;

    .search {
      margin-bottom: 0px;
    }
  }
}

.header_notification {
  background-color: #202020;
  position: absolute;
  top: 10px;
  right: 85px;
  border: none;
  width: 55px;
  height: 32px;
  border-radius: 8px;

  &:hover {
    svg {
      color: #fff;
      filter: drop-shadow(0px 0px 5px #fff);
    }
  }

  svg {
    color: gray;
    transition: 0.15s ease-in-out;
  }
}

.notifications-window {
  position: absolute;
  top: 50px;
  right: 130px;
  background: #202020;
  color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100000;
  border-radius: 8px;
  width: 400px;

  .main {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.first_notification p {
  margin: 0px 15px 0px 15px;
  font-size: small;
  width: 325px;
}

.first_notification {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  border-bottom: 1px solid rgb(65, 65, 65);
  margin: 0px;
  width: 100%;
  padding: 5px;

  &:hover {
    background-color: #2b2b2b;
    border-radius: 8px;
  }

  & p {
    display: flex;
    flex-direction: column;
  }
}

.notification_header {
  display: flex;
  background-color: #2b2b2b;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 400px;
  height: 40px;
  margin-top: -20px;
  margin-left: -19px;
  align-items: center;
}

.notification_header button {
  background-color: #f08921;
  border: none;
  width: 60px;
  height: 20px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
}

.notification_header p {
  text-decoration: underline;
  font-size: small;
  color: #f08921;
  cursor: pointer;
  margin: 0;
  margin-left: 120px;
}

.notification_header h6 {
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.notification_footer {
  display: flex;
  background-color: #2b2b2b;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 419px;
  height: 40px;
  margin-left: -19px;
  margin-bottom: -20px;
  margin-top: -1px;
  align-items: center;
  justify-content: center;
}

.notification_footer p {
  align-items: center;
  font-size: small;
  margin: 0;
  text-decoration: underline;
  color: #f08921;
  cursor: pointer;
}

.close {
  cursor: pointer;
}

.header .dropdown .dropdown-content a,
.header .dropdown .dropdown-content span {
  background-color: unset !important;
  border-radius: 3px;
  transition: 0.15s ease-in-out;
  margin: 3px;
}

.header .dropdown .dropdown-content {
  background-color: #202020;
  border-radius: 6px;
  border: 1px solid #101010;
}

.header .dropdown .dropdown-content a:hover,
.header .dropdown .dropdown-content span:hover {
  background-color: #303030 !important;
  color: white;
  filter: drop-shadow(0px 0px 3px gray);
}

.search_btn_active {
  background-color: #f08921;
}

.search_heading {
  color: #f08921;
}
