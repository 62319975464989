@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/typography';
@import 'abstracts/buttons';
@import 'abstracts/forms';
@import '~intro.js/introjs.css';
@import 'pages/home';
@import 'pages/sidebar';
@import 'pages/store';
@import 'pages/store_queue.scss';
@import 'pages/projectlist';
@import 'pages/auth';
@import 'pages/addproject.scss';
@import 'pages/reviewqueue';
@import 'pages/composerview';
@import 'pages/ticketdetails';
@import 'pages/account';
@import 'pages/forms';
@import 'pages/buttons';
@import 'pages/projectdetails';
@import 'pages/documentation.scss';
@import 'pages/plugindetails.scss';
@import 'pages/commandline.scss';

body {
  font-family: $font-family;
  font-weight: normal;
  background-color: $primary-bg;

  a,
  span {
    display: inline-block;
  }
}

.main {
  padding-top: 20px !important;
}

table.table th,
table.table td {
  color: #888888;
  font-size: 14px;
  border-bottom: 1px solid #202020;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: normal;
  padding: 25px;
}

.form-check-input {
  width: 0.8em;
  height: 0.8em;
  background-color: $secondary-bg;
  border: 1px solid $secondary-color;

  &:focus {
    box-shadow: none;
  }

  &:checked[type='checkbox'] {
    background: none;
    background-color: $primary-color;
    border: 1px solid $grey-color;
  }

  &:checked[type='radio'] {
    background: none;
    background-color: $primary-color;
    border: 1px solid $grey-color;
  }
}

.form-check-input[type='checkbox'] {
  border-radius: 0.2em;
  margin-top: 0%;
}

.form-check-input[type='radio'] {
  border-radius: 0.2em;
}

span.ticket_status {
  background: green;
  width: auto;
  padding: 5px 10px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
}

/*Css for Loader*/

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid $primary-color;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinning_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;

  .loader-text {
    color: $primary-color;
    margin-right: 10px;

    &::after {
      display: inline-block;
      animation: dotty steps(1, end) 1s infinite;
      content: '';
    }

    @keyframes dotty {
      0% {
        content: '';
      }

      25% {
        content: '.';
      }

      50% {
        content: '..';
      }

      75% {
        content: '...';
      }

      100% {
        content: '';
      }
    }
  }
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

.border-b {
  border-bottom: 1px solid $darkgray;
}

.border-t {
  border-top: 1px solid $darkgray;
}

// modal

.modal-dialog {
  max-width: 800px;

  @media only screen and (min-width: 1400px) {
    max-width: 1341px;
  }

  .modal-content {
    padding: 60px 40px 22px 40px;

    @media only screen and (min-width: 1400px) {
      padding: 81px 74px 22px 67px;
    }
  }
}

// introjs CSS
.introjs-tooltip {
  color: #fff;
  font-size: 18px;
  background-color: #101010;
  box-shadow: 0px 0px 20px 0px #6db1bf;
  border-radius: 10px;
  min-width: 300px;

  // &.introjs-right {
  //   margin-left: -10px;
  //   margin-top: -20px;
  // }

  // &.introjs-bottom-left-aligned {
  //   margin-left: -20px;
  //   margin-top: -10px;
  // }

  // &.introjs-top-left-aligned {
  //   margin-left: -30px;
  //   margin-right: 0px;
  //   margin-top: 0px;
  //   margin-bottom: -10px;
  // }

  // &.introjs-left {
  //   margin-left: 0px;
  //   margin-right: -10px;
  //   margin-top: -20px;
  // }

  .introjs-tooltip-header {
    padding: 44px 12px 12px;
    position: relative;

    .introjs-tooltip-title {
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      width: 100%;
    }

    .introjs-skipbutton {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 42px;
      color: #ffffff;
      font-weight: 100;
    }
  }

  .introjs-tooltiptext {
    padding: 0 12px 50px;
    text-align: center;

    .introjs_logo {
      text-align: center;
      max-width: 200px;
      margin: 0 auto;

      svg {
        width: 100%;
      }
    }

    p {
      font-size: 16px;
      color: #ffffff;
      text-align: center;
    }
  }

  .introjs-tooltipbuttons {
    display: flex;
    gap: 15px;
    padding: 20px 12px;
    border-top: 0;

    &::after {
      display: none;
    }

    .introjs-button {
      box-sizing: content-box;
      text-decoration: none;
      width: calc((100% - 30px) / 2);
      text-align: center;
      text-shadow: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      padding: 10px 20px;
      border-radius: 10px;
      cursor: pointer;

      &.introjs-prevbutton {
        border: 1px solid #909090;
        background-color: #101010;
        transition: all 0.2s ease-in-out;
        color: #909090;

        &:hover {
          background-color: rgb(144 144 144 / 20%);
          border: 1px solid #909090;
          color: #909090;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid #909090;
          background-color: #101010;
          color: #909090;
        }
      }

      &.introjs-nextbutton {
        border: 1px solid #f28a03;
        background-color: #f28a03;
        transition: all 0.2s ease-in-out;
        color: #ffffff;

        &:hover {
          background-color: rgba(240, 137, 33, 0.2) !important;
          border: 1px solid #f28a03 !important;
          color: #f28a03 !important;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid #f28a03;
          background-color: #f28a03;
          color: #ffffff;
        }
      }
    }
  }

  .introjs-bullets {
    ul {
      li {
        a {
          background: #6db1bf;
          width: 10px;
          height: 10px;

          &:hover,
          &:focus {
            width: 25px;
            background: #6db1bf;
          }

          &.active {
            width: 25px;
            height: 10px;
            background: #f28a03;
          }
        }
      }
    }
  }

  .introjs-progress {
    background-color: #202020;
    margin: 10px 12px;
    box-shadow: 0px 0px 4px 0px #000000 inset;
    border-radius: 15px;

    .introjs-progressbar {
      background-color: #6db1bf;
    }
  }

  .introjs-arrow {
    display: none !important;

    &.left,
    &.right,
    &.top,
    &.bottom {
      display: none !important;
    }
  }
}

.introjs-helperLayer {
  box-shadow: 0px 0px 20px 0px #6db1bf !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

// .introjs-showElement {
//   z-index: 999999999 !important;
// }

// .home_main .linkdata .listgrid.introjs-relativePosition {
//   outline: 5px solid #202020;
//   overflow: hidden;
// }

// .home_main .linkdata .listgrid.introjs-relativePosition:hover {
//   outline: 5px solid #2b2b2b;
// }

body .introjs-overlay {
  opacity: 1;
  backdrop-filter: blur(5px);
  background-color: #0000001a;
}

.btn_right {
  position: absolute;
  right: 5px;
}

.lightbox {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightbox-content {
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 20px;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: #6db1bc;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  right: 20px;
  padding: 16px;
  margin-top: -50px;
  color: #6db1bc;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover,
.close:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.composer-model {
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 56px);

    .modal-header {
      // border-bottom: 1px solid #909090
      border: none;
      padding-top: 0px;
      padding-bottom: 20px;

      h4 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        margin-bottom: 0px;
      }

      svg {
        font-size: 25px;
        line-height: 1;
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }

    .modal-footer {
      border: none;
    }

    .modal-content {
      background-color: #101010;

      span {
        width: 170px;
        font-size: 14px;
        background-color: #2b2b2b;
        border: 1px solid #505050;
        color: white;
        white-space: break-spaces;
        word-break: break-word;
      }

      select {
        background-color: transparent;
        font-size: 14px;
        border: 1px solid #505050;
        color: #909090;
      }
    }

    .modal-content {
      .modal-body {
        padding: 0px;

        ul {
          padding: 16px 16px 5px;

          &.nav-pills {
            border-bottom: 1px solid $secondary-bg;

            .nav-item {
              padding: 0 20px;

              .nav-link {
                font-size: 16px;
                line-height: 24px;
                color: #909090;
                padding: 0;
                position: relative;

                &:hover {
                  color: $primary-color;
                  background-color: transparent;

                  &::after {
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    content: '';
                    background-color: $primary-color;
                    width: 100%;
                    height: 3px;
                  }
                }

                &.active {
                  color: $primary-color;
                  background-color: transparent;

                  &::after {
                    position: absolute;
                    bottom: -7px;
                    left: 0;
                    content: '';
                    background-color: $primary-color;
                    width: 100%;
                    height: 3px;
                  }
                }
              }
            }
          }
        }

        .tab-content {
          padding: 0 16px;

          .tab-pane {
            &#pills-wp {
              margin-top: 30px;
            }

            &:not(#pills-wp) {
              margin-top: 43px;
            }

            &#pills-composer {
              .addproject {
                .upload {
                  width: 100%;
                  border-color: #f28a03;
                  border-width: 0 0 0 1px;
                  border-style: solid;
                }
              }

              .login_register {
                margin-bottom: 32px;

                span {
                  width: auto;
                  background-color: transparent;
                  border: none;
                }

                .switch {
                  height: 12px !important;
                  width: 26px !important;

                  input {
                    &:checked {
                      + .slider {
                        &::before {
                          transform: translate(76%, 10%);
                        }
                      }
                    }
                  }

                  .slider {
                    &::before {
                      left: 0;
                      bottom: 0;
                      height: 16px !important;
                      width: 16px !important;
                      transform: translate(-12%, 10%);
                    }
                  }
                }
              }

              .file-btn {
                background-color: #6db1bc;
                color: #ffffff;
                border: 1px solid #6db1bc;
                border-radius: 7px;
                padding: 9px 22px;
                font-size: 14px;
                transition: all 0.2s ease-in;

                &:hover {
                  background-color: rgba(109, 177, 188, 0.2);
                  color: #6db1bc;
                  border-radius: 7px;
                }

                label {
                  cursor: pointer;
                }
              }
            }

            &#pills-ftp {
              .addproject {
                .file-btn {
                  background-color: $primary-color;
                  border: 1px solid $primary-color;
                  border-radius: 7px;
                  transition: all 0.2s ease-in;

                  &:hover {
                    color: $primary-color;
                    background-color: rgba(240, 137, 33, 0.2);
                  }

                  label {
                    cursor: pointer;
                  }
                }

                .input-group-text {
                  width: 250px;
                }

                .upload {
                  width: 100%;
                  border-color: #f28a03;
                  border-width: 0 0 0 1px;
                  border-style: solid;
                }
              }
            }

            &#pills-ssh {
              .addproject {
                .file-btn {
                  background-color: $primary-color;
                  border: 1px solid $primary-color;
                  border-radius: 7px;
                  transition: all 0.2s ease-in;

                  &:hover {
                    color: $primary-color;
                    background-color: rgba(240, 137, 33, 0.2);
                  }

                  label {
                    cursor: pointer;
                  }
                }

                .input-group-text {
                  width: 250px;
                }

                .upload {
                  width: 100%;
                  border-color: #f28a03;
                  border-width: 0 0 0 1px;
                  border-style: solid;
                }
              }
            }

            .addproject {
              .input-group {
                margin-bottom: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}

.not-found-page {
  background-color: #2f3242;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -400px;
  }

  .message-box {
    height: 200px;
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: 50px;
    color: #fff;
    font-family: Roboto;
    font-weight: 300;

    h1 {
      font-size: 60px;
      line-height: 46px;
      margin-bottom: 40px;
    }
  }

  .buttons-con {
    .action-link-wrap {
      margin-top: 40px;
    }

    .action-link-wrap a {
      background: #68c950;
      padding: 8px 25px;
      border-radius: 4px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      transition: all 0.3s linear;
      cursor: pointer;
      text-decoration: none;
      margin-right: 10px;

      &:hover {
        background: #5a5c6c;
        color: #fff;
      }
    }
  }

  #Polygon-1,
  #Polygon-2,
  #Polygon-3,
  #Polygon-4,
  #Polygon-4,
  #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }

  #Polygon-2 {
    animation-delay: 0.2s;
  }

  #Polygon-3 {
    animation-delay: 0.4s;
  }

  #Polygon-4 {
    animation-delay: 0.6s;
  }

  #Polygon-5 {
    animation-delay: 0.8s;
  }

  @keyframes float {
    100% {
      transform: translateY(20px);
    }
  }

  @media (max-width: 450px) {
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -250px;
      margin-left: -190px;
    }

    .message-box {
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -190px;
      text-align: center;
    }
  }
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.5s ease;
}

.notification.hidden {
  opacity: 0;
}

.roundedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #888888;
  border: #000000;
  color: #000000;
}

.socond_menu {
  ul {
    li {
      a {
        position: relative;
      }

      button {
        position: relative;
      }
    }
  }
}

// .sidebar--Collapse{
//   background-color: $primary-bg;
//   border-right: 1px solid $secondary-bg;
//   width:240px;
//   padding: 17px 12px 0 12px;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   .socond_menu ul {
//     list-style: none;
//     padding: 0;
//     li {
//       margin-bottom: 30px;
//     }
//   }
// .socond_menu {
//   margin-top: -10px !important;
// }
// }

// header.header{
//   left: 60px;
// }
// .sidebar{
//   padding-left: 60px !important;
// }
.second-aside {
  -moz-transition: width 0.5s ease-out;
  -o-transition: width 0.5s ease-out;
  -webkit-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
  display: inline-block;
  z-index: 0;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left !important;

  .collapseToggle {
    position: relative;
    top: 500px;
    padding-left: 4px;
    display: block;
    cursor: pointer;
    padding-right: 5px;

    #toggleIcon {
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -moz-transition: transform 0.4s ease-out;
      -o-transition: transform 0.4s ease-out;
      -webkit-transition: transform 0.4s ease-out;
      transition: transform 0.4s ease-out;

      &.rotate {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -moz-transition: transform 0.4s ease-out;
        -o-transition: transform 0.4s ease-out;
        -webkit-transition: transform 0.4s ease-out;
        transition: transform 0.4s ease-out;
      }
    }
  }

  .menuText {
    top: 1px;
    left: 40px;
    opacity: 0;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    position: absolute;
    color: #ffffff;
    font-weight: 500;
    white-space: nowrap;
  }
}

.sidebar--Collapse {
  width: 200px !important;
  -moz-transition: width 0.6s ease-out;
  -o-transition: width 0.6s ease-out;
  -webkit-transition: width 0.6s ease-out;
  transition: width 0.6s ease-out;

  .menuText {
    opacity: 1;
    -moz-transition: opacity 0.6s 0.2s ease-out;
    -o-transition: opacity 0.6s 0.2s ease-out;
    -webkit-transition: opacity 0.6s 0.2s ease-out;
    transition: opacity 0.6s 0.2s ease-out;
    position: absolute;
  }
}

.main {
  margin-left: 60px;
  -moz-transition: margin-left 0.55s ease-out;
  -o-transition: margin-left 0.55s ease-out;
  -webkit-transition: margin-left 0.55s ease-out;
  transition: margin-left 0.55s ease-out;
}

.main--slide {
  -moz-transition: margin-left 0.6s ease-out;
  -o-transition: margin-left 0.6s ease-out;
  -webkit-transition: margin-left 0.6s ease-out;
  transition: margin-left 0.6s ease-out;
  // margin-left: 200px;
}

.first-aside {
  margin-left: 60px;
  -moz-transition: margin-left 0.55s ease-out;
  -o-transition: margin-left 0.55s ease-out;
  -webkit-transition: margin-left 0.55s ease-out;
  transition: margin-left 0.55s ease-out;
  position: relative;

  .first-aside-inner {
    position: sticky;
    top: 33px;
  }
}

.first--Collapse {
  -moz-transition: margin-left 0.6s ease-out;
  -o-transition: margin-left 0.6s ease-out;
  -webkit-transition: margin-left 0.6s ease-out;
  transition: margin-left 0.6s ease-out;
  margin-left: 200px;
}

.menuText:hover {
  color: #f08921;
}

.collapseToggleIcon {
  margin-left: 51px;
  -moz-transition: margin-left 0.6s ease-out;
  -o-transition: margin-left 0.6s ease-out;
  -webkit-transition: margin-left 0.6s ease-out;
  transition: margin-left 0.6s ease-out;
}

.collapseToggleIconOpen {
  margin-left: 191px;
  -moz-transition: margin-left 0.6s ease-out;
  -o-transition: margin-left 0.6s ease-out;
  -webkit-transition: margin-left 0.6s ease-out;
  transition: margin-left 0.6s ease-out;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  background-color: #f08921;
  width: 40px;
  height: 40px;
  /* Ensure the height is consistent */
  margin-left: 0.5rem;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
  top: 100%;
  /* Position below the icon */
  right: 0;
  cursor: pointer;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
  display: block;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
  display: block;
}

.dropdown-content span:hover {
  background-color: #ddd;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.plug_grid {
  background: $secondary-bg;
  border-radius: 25px;
  padding: 18px;
  transition: $transition-ease;
  height: 100%;
  position: relative;

  &:hover {
    background: $third-bg;
  }

  .plug_img {
    position: relative;

    .select_favorite {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
      background-color: #202020;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 17px;
      }
    }
  }

  .price {
    font-size: $font36;
    font-weight: 600;
    color: $primary-color;
  }

  .plugin_select_btn {
    text-align: right;
  }

  .store-item-image {
    border-radius: 16px;
  }

  .pluginname {
    font-size: $font20;
    color: #ffffff;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    display: -webkit-box;
    // width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .author {
    // font-size: $font12;
    color: $grey-color;
    margin-bottom: 10px;
  }

  .up_date {
    // font-size: $font14;
    color: $primary-color;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .store-item-image {
    border: 3px solid transparent;
  }

  //  .favorite_select {
  //      position: absolute;
  //      right: 10px;
  //      top: 10px;
  //      cursor: pointer;
  //      opacity: 50%;
  //  }
  &:hover .store-item-image {
    // background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)) padding-box,-webkit-gradient(linear , left top, right bottom , color-stop(0, #F08921), color-stop(33%, transparent),color-stop(66%, transparent), to(#F08921)) border-box;
    // background: -o-linear-gradient(transparent, transparent) padding-box,-o-linear-gradient(top left , #F08921 0, transparent 33%,transparent 66%, #F08921 100%) border-box;
    // background: linear-gradient(transparent, transparent) padding-box,linear-gradient(to bottom right , #F08921 0, transparent 33%,transparent 66%, #F08921 100%) border-box;
    /* border-radius: 13px; */
    border: 3px solid transparent;
    color: #ffffff;
    line-height: 1;
    font-weight: 500;
  }

  &:hover .favorite_select {
    opacity: 1;
  }

  &:hover .store_primary_btn {
    background: -webkit-gradient(linear, left top, left bottom, from(#3f686f), to(#3f686f)) padding-box,
      -webkit-gradient(
          linear,
          left top,
          right bottom,
          color-stop(0, #6db1bc),
          color-stop(40%, #213639),
          color-stop(0%, #213639),
          to(#6db1bc)
        ) border-box;
    background: -o-linear-gradient(#3f686f, #3f686f) padding-box,
      -o-linear-gradient(top left, #6db1bc 0, #213639 40%, #213639 0%, #6db1bc 100%) border-box;
    background: linear-gradient(#3f686f, #3f686f) padding-box,
      linear-gradient(to bottom right, #6db1bc 0, #213639 40%, #213639 0%, #6db1bc 100%) border-box;
  }
}

.main_logo {
  a {
    display: flex;
    align-items: center;
    gap: 15px;

    img {
      width: auto;
      height: 100%;
      max-height: 33px;

      &.logo_text {
        width: auto;
        height: 100%;
        max-height: 18px;
      }
    }
  }
}

.sidebar_icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title_text {
  color: white;
}

.input_title_text {
  color: #5f5f5f;
  margin-bottom: 5px;
}
.remove_btn {
  border: none;
  background-color: #fd2525;
  padding: 5px 20px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
}
.remove_btn:hover {
  color: #fd2525;
  border: 1px solid #fd2525;
  background-color: #fd25253d;
}
.remove_btn:disabled {
  background-color: grey;
  cursor: not-allowed;
  border: none;
  color: white;
}
.filter {
  margin-right: 10px;
}
.queue_main {
  margin: 0px;
  .inner_header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 15px;
    padding-bottom: 22px;
    border-bottom: 1px solid #202020;

    .field-group {
      display: block;
      width: 100%;

      .input_title_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #909090;
      }
    }
  }

  .queue_table_wrap {
    max-height: calc(100vh - 157.71px);
    overflow: hidden scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .queue_table {
    tr {
      &:last-child {
        td {
          border-bottom: 0px;
        }
      }
    }

    th {
      padding: 15px 20px;
      vertical-align: bottom;
      font-size: 16px;

      &:last-child {
        text-align: center;
        width: 131.27px;
      }
    }

    td {
      padding: 30px 20px;

      &:first-child {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }

      &:last-child {
        text-align: center;
        width: 131.27px;
      }
    }
  }

  .update_container {
    background-color: #202020;
    padding: 0 20px;

    .update_container_header {
      display: flex;
      justify-content: space-between;
      padding: 10px 24px;
      align-items: center;

      .title_text {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #fff;
      }
    }

    .project_container {
      max-height: calc(100vh - 125px);
      overflow: hidden scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .inner_project_container {
        border-width: 0px 1px 1px 1px;
        border-color: #505050;
        border-style: solid;
        border-radius: 5px;
        background-color: #101010;
        margin-bottom: 15px;

        .inner_project_container_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 24px;
          border-radius: 5px;
          border-width: 1px 0px 1px 0px;
          border-color: #505050;
          border-style: solid;
          border-radius: 5px;
          background: #2b2b2b;

          .title_text {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #fff;
          }
        }

        .queue_table_group {
          padding: 20px 24px;

          .queue_table {
            th {
              background-color: #1a1a1a;
              padding: 10px 20px;
              font-size: 16px;

              &:first-child {
                color: #fff;
              }
            }

            td {
              &:first-child {
                font-size: 16px;
                font-weight: 500;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.main_queue_header {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 10px 24px;
}

.project_queue_table {
  max-width: 1190px;
  margin-left: auto;
  width: 100%;
  padding: 10px 10px 20px 24px;

  .queue_table {
    &.table {
      margin-bottom: 35px;
      th {
        color: #fff;
        font-size: 14px;
        border-bottom: 1px solid #101010;
        white-space: nowrap;
        vertical-align: middle;
        font-weight: normal;
        padding: 16px 24px;
        background-color: #202020;
      }
      td {
        color: #909090;
        font-size: 14px;
        border-bottom: none;
        white-space: nowrap;
        vertical-align: middle;
        font-weight: normal;
        padding: 20px 24px;
        background-color: #202020;

        &:first-child {
          color: #fff;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}

.message_container {
  max-width: 1188px;
  margin-left: auto;

  width: 100%;
  padding: 10px 10px 20px 24px;

  .inner_message_container {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 12px;
    background-color: #202020;
    color: #909090;
  }
  span {
    margin-left: 11px;
  }
}

.queue_deploy_btn {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px 10px 24px;
}

.product_like {
  position: absolute;
  right: 21px;
  top: 25px;
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #68686880;
  border-radius: 25px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #202020;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(240, 137, 33);
  border-radius: 10px;
  border: 3px solid #202020;
  transition: background 0.3s, transform 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 165, 0);
  transform: scale(1.1);
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(240, 137, 33) #202020;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 165, 0);
}
.favorite {
  background-color: #f08921;
}

.sidenavicon {
  overflow: visible;
  height: 18px;
  width: 18px;
  align-items: start;
  color: gray;
}

.back-icon-hover1 {
  transition: 0.16s ease-in-out !important;
  color: gray;
}

.back-icon-hover1:hover {
  cursor: pointer;
  opacity: 1 !important;
  filter: drop-shadow(0px 0px 5px silver);
  color: #fff;
}

.sidebar .active {
  position: relative; /* Ensures the dot is placed correctly */
}

.sidebar .active::after {
  content: '';
  position: absolute;
  width: 26px;
  height: 20px;
  bottom: -10px;
  left: -2.5px;
  background: none;
  border: none;
  color: #ffffff;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-image: none;
  border-color: #f08921;
  border-width: 3px;
  margin-bottom: -2px;
  box-shadow: 0px -12px 5px -10px inset #f08921;
  border-radius: 2px;
}

.tooltip_copy {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  position: absolute;
  top: -5px;
}

.reverse_orange_btn {
  color: white;
  border-radius: 7px;
  border: none;
  padding: 9px 22px;
  font-size: 14px;
  transition: $transition-ease;
  border: 1px solid #f08921;
  height: 40px;
  color: #f08921;
  background-color: rgba(240, 137, 33, 0.2);
  &:hover {
    color: white;
    background-color: #f08921;
  }
}

.sort-icon {
  font-size: 1.5rem; /* Adjust size (make them bigger) */
  margin: 0 5px; /* Add space between the icons */
  cursor: pointer; /* Makes the icons look clickable */
  margin-top: 17px;
  // color: rgb(240, 137, 33);
  color: #fff;
  transition: 0.15s ease-in-out;
  &:hover {
    color: #f08921;
    filter: drop-shadow(0px 0px 5px #f08921);
  }
}

.sort-icon1 {
  font-size: 2rem; /* Adjust size (make them bigger) */
  margin: 0 5px; /* Add space between the icons */
  cursor: pointer; /* Makes the icons look clickable */
  margin-bottom: 17px;
  // color: rgb(240, 137, 33);
  color: #fff;
  transition: 0.15s ease-in-out;
  &:hover {
    color: #f08921;
    filter: drop-shadow(0px 0px 5px #f08921);
  }
}

.search_filter_menu_slide {
  -moz-transition: padding-left 0.6s ease-out;
  -o-transition: padding-left 0.6s ease-out;
  -webkit-transition: padding-left 0.6s ease-out;
  transition: padding-left 0.6s ease-out;
  padding-left: 200px !important;
}

.error_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 70px);
  height: 100%;

  .error_block_inner {
    max-width: 644px;
    width: 100%;
    border: 10px solid #f28a03;
    box-shadow: 0px 4px 50px 0px #f28a03;
    border-radius: 20px;
    padding: 50px;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 248px;
      height: 235px;
      background-image: url(../../src/img/power-failure.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top: -135px;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 1;
    }
    &::after {
      position: absolute;
      content: '';
      width: 100px;
      height: 200px;
      background-color: #101010;
      top: -100px;
      left: 50%;
      transform: translate(-50%, 0%);
      z-index: 0;
    }

    h2 {
      font-size: 60px;
      font-weight: 400;
      line-height: 90px;
      text-align: left;
      text-shadow: 0px 0px 2px rgba(242, 138, 3, 1), 0px 0px 10px rgba(242, 138, 3, 0.9),
        0px 0px 0px rgba(242, 138, 3, 0.9), 0px 0px 30px rgba(242, 138, 3, 0.8), 0px 0px 30px rgba(242, 138, 3, 0.9);
    }

    p {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      color: #fff;
    }

    .orange_btn {
      padding: 12px 18px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }
  }
}
