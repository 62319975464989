$font-family: 'Barlow';
$Barlow-font-path: '../fonts/Barlow';
$white: #ffffff;
$black: #000000;
$primary-color: #f08921;
$primary-color-alpha: rgba(240, 137, 33, 0.2);
$primary-color-dark: #974c00;
$secondary-color: #888888;
$grey-color: #7a7a7a;
$primary-bg: #101010;
$secondary-bg: #202020;
$blueprimary: #3f686f;
$bluesecondary: #6db1bc;
$bluesecondary-alpha: rgba(109, 177, 188, 0.2);
$bluedark: #213639;
$third-bg: #2b2b2b;
$third-color: #505050;
$darkgray: #909090;
$darkgray-alpha: rgba(144, 144, 144, 0.2);
$green-color: #008000;
$green-tag: #13a53f;
$red-color: #ff0000;
$redsecondary: rgba(255, 0, 0, 0.2);
$font36: 36px;
$font30: 30px;
$font20: 20px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;
$accordion-button-icon: url('../../img/expand.svg');
$transition-ease: all 0.2s ease-in;
$transition-linear: all 1s linear;
