.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo h5 {
  width: 365px;
  text-align: center;
  margin-top: 40px;
  font-size: 15px;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* #exampleModal{
    display: flex;
    justify-content: center;
    align-items: center;
} */
