.review_queue {
  margin-top: 15px;
  .form-check-input:checked[type='radio'] {
    background: #0b7708;
  }

  .form-check-input:checked {
    background-color: #0b7708;
    border-color: #0b7708;
  }
  .form-check-label {
    color: #fff;
    font-size: 14px;
  }
  .form-check .form-check-input {
    margin-left: 0em;
    margin-right: 10px;
  }
  .accordion-item {
    color: #fff;
    background-color: transparent;
    border: 1px solid #2b2b2b;
    margin-bottom: 15px;
    .accordion-button:not(.collapsed),
    .accordion-button {
      background-color: #2b2b2b;
      padding-top: 0;
      padding-bottom: 0%;
      padding-right: 0;
      border: none;
      overflow: hidden;
      color: #fff;
      box-shadow: none;
      &::after {
        background-image: url(../../img/acc-arw.svg);
        background-color: #74b1bc;
        height: 52px;
        width: 52px;
        background-position: center;
        transform: rotate(-90deg);
      }
    }
    .accordion-button:not(.collapsed):after {
      transform: rotate(0deg);
    }

    .accordion-body {
      background-color: transparent;
      .table {
        color: #fff;
        .env_nm {
        }
        thead {
          background-color: #1a1a1a;
        }
        tbody {
          background-color: #1a1a1a;
        }
        > :not(:first-child) {
          border-top: none;
        }
        td {
          border: none;
          &:first-child {
            color: #fff;
          }
        }
        th {
          padding-top: 0px;
          padding-bottom: 0px;
          color: #fff;
        }
      }
    }
  }
}
