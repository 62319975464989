.main_notification {
  margin: auto;
  width: 100%;
  //   border: 1px solid grey;
  //   padding: 10px;
  height: 100vh;

  .all_notification_header {
    align-items: center;
    padding: 15px 0px 0px 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #202020;
    p {
      color: white;
      //   &:hover {
      //     color: #1f48eb;
      //   }
    }
  }

  .notification_content {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    column-gap: 300px;
    border-bottom: 1px solid #202020;

    .notification_bell {
      background-color: #202020;
      width: 40px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      text-align: center;
      align-content: center;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      margin: 20px 0px 10px 40px;
    }
    .notification_message {
      width: 430px;
      p {
        margin-top: 20px;
      }
    }
    .notification_time {
      display: flex;
      align-items: center;
      span {
        color: #909090;
      }
      p {
        margin-left: 10px;
        margin-top: 20px;
      }
    }
    .notification_read {
      p {
        cursor: pointer;
        margin-right: 30px;
        margin-top: 20px;
        color: #738bed;
        text-wrap: nowrap;
        &:hover {
          color: #1f48eb;
        }
      }
    }
  }
}
