.font16 {
  font-size: $font16;
}

.font20 {
  font-size: $font20;
}

main.main,
main.main {
  width: 100%;
  // width: calc(100%);
}

main.main.main-side {
  // width: calc(100% - 250px);
  margin-left: 20;
  // width: auto;
}

.main.main-side-sort {
  // width: calc(100% - 60px);
  width: 100%;
}

@media (max-width: 767px) {
  main.main.main-side {
    width: calc(100% - 250px);
    width: 100%;
  }
}

.sorttxt {
  font-size: $font12;
}

.list_view,
.grid_view {
  cursor: pointer;

  svg * {
    fill: #d9d9d9;
  }

  &:hover,
  &.selected {
    svg * {
      fill: $primary-color;
    }
  }
}

.selectbox {
  background-image: $accordion-button-icon;
  background-color: $secondary-bg;
  color: $secondary-color;
  border: none;
  font-size: $font12;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.store_listab {
  .nav-tabs {
    border-bottom: 2px solid $secondary-bg;

    &.store-first {
      .nav-item {
        padding: 8px 16px 0 !important;
        display: flex;
        align-items: flex-end !important;

        .nav-link {
          background: none;
          border: none;
          color: $secondary-color;
          font-size: $font14;
          padding: 0 0 8px;
          margin-bottom: -2px !important;
          overflow: hidden;
          position: relative;

          &.active {
            background: none;
            color: #ffffff;
            border-style: solid;
            border-image: none;
            border-color: #f08921;
            margin-bottom: -2px;
            border-radius: 2px;
            border: 0 !important;
            box-shadow: none !important;

            &::before {
              content: '';
              width: 100%;
              position: absolute;
              height: 5px;
              bottom: 0px;
              left: 0px;
              background-color: #f08921;
              filter: blur(3px);
              border-radius: 100%;
            }

            &::after {
              content: '';
              width: 100%;
              position: absolute;
              height: 3px;
              bottom: 0px;
              left: 0px;
              background-color: #f08921;
              border-radius: 3px;
            }
          }

          &:hover {
            color: #ffffff;
          }
        }

        // .nav-link {
        //   background: none;
        //   border: none;
        //   color: $secondary-color;
        //   font-size: $font14;
        //   padding: 0 0 8px;

        //   &.active {
        //     background: none;
        //     border: none;
        //     color: #ffffff;
        //     border-style: solid;
        //     border-top: 0;
        //     border-left: 0;
        //     border-right: 0;
        //     border-image: none;
        //     border-color: #f08921;
        //     border-width: 3px;
        //     margin-bottom: -2px;
        //     box-shadow: 0px -12px 5px -10px inset #f08921;
        //     border-radius: 2px;
        //     // padding: 0 0 8px;
        //   }

        //   &:hover {
        //     color: #ffffff;
        //   }
        // }
      }
    }
  }

  .total-plug {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: $secondary-color;
    font-size: $font12;

    span {
      color: $primary-color;
      margin-right: 5px;
    }
  }

  .plug_grid {
    background: $secondary-bg;
    border-radius: 25px;
    padding: 18px;
    transition: $transition-ease;
    height: 100%;
    position: relative;

    &:hover {
      background: $third-bg;
    }

    .plug_img {
      position: relative;

      .select_favorite {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
        background-color: #202020;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 17px;
        }
      }
    }

    .price {
      font-size: $font36;
      font-weight: 600;
      color: $primary-color;
    }

    .plugin_select_btn {
      text-align: right;
    }

    .store-item-image {
      border-radius: 16px;
    }

    .pluginname {
      font-size: $font20;
      color: #ffffff;
      font-weight: 600;
      margin-top: 18px;
      margin-bottom: 0px;
      text-overflow: ellipsis;
      display: -webkit-box;
      // width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .author {
      // font-size: $font12;
      color: $grey-color;
      margin-bottom: 10px;
    }

    .up_date {
      // font-size: $font14;
      color: $primary-color;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .store-item-image {
      border: 3px solid transparent;
    }

    //  .favorite_select {
    //      position: absolute;
    //      right: 10px;
    //      top: 10px;
    //      cursor: pointer;
    //      opacity: 50%;
    //  }
    &:hover .store-item-image {
      // background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)) padding-box,-webkit-gradient(linear , left top, right bottom , color-stop(0, #F08921), color-stop(33%, transparent),color-stop(66%, transparent), to(#F08921)) border-box;
      // background: -o-linear-gradient(transparent, transparent) padding-box,-o-linear-gradient(top left , #F08921 0, transparent 33%,transparent 66%, #F08921 100%) border-box;
      // background: linear-gradient(transparent, transparent) padding-box,linear-gradient(to bottom right , #F08921 0, transparent 33%,transparent 66%, #F08921 100%) border-box;
      /* border-radius: 13px; */
      border: 3px solid transparent;
      color: #ffffff;
      line-height: 1;
      font-weight: 500;
    }

    &:hover .favorite_select {
      opacity: 1;
    }

    &:hover .store_primary_btn {
      background: -webkit-gradient(linear, left top, left bottom, from(#3f686f), to(#3f686f)) padding-box,
        -webkit-gradient(
            linear,
            left top,
            right bottom,
            color-stop(0, #6db1bc),
            color-stop(40%, #213639),
            color-stop(0%, #213639),
            to(#6db1bc)
          ) border-box;
      background: -o-linear-gradient(#3f686f, #3f686f) padding-box,
        -o-linear-gradient(top left, #6db1bc 0, #213639 40%, #213639 0%, #6db1bc 100%) border-box;
      background: linear-gradient(#3f686f, #3f686f) padding-box,
        linear-gradient(to bottom right, #6db1bc 0, #213639 40%, #213639 0%, #6db1bc 100%) border-box;
    }
  }

  .listmode {
    .plug_grid {
      .price {
        text-align: center;
        // line-height: 1;
      }

      .plugin_select_btn {
        margin-top: 20px;
        text-align: center;
      }
    }
  }

  .paginate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 30px 0;
    gap: 10px;

    p,
    button {
      background: #233639;
      border: none;
      width: 40px;
      height: 40px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 5px;
      color: #ffffff;
      margin: 0px 0;
      font-size: 16px;

      &:hover,
      &.paginate-current {
        background-color: $primary-color;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .list_header {
    // border-bottom: 2px solid $secondary-bg;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .total_plug_sort {
      margin-top: 0px;

      .sort_filter {
        margin-top: -45px;

        .sorttxt {
          font-size: 14px;
        }

        .selectbox {
          width: 100% !important;
          max-width: 210px;
          background-color: #202020;
          color: #ffffff;
          height: 44px;
          background-image: url('../../img/down_arrow_yellow.svg');
          font-size: 14px;
          border: 1px solid #202020;
        }
      }
    }
  }
}

.store-second {
  .select_filter_group {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    justify-content: space-between;

    .select_env {
      .environment-select {
        width: 100% !important;
        max-width: 365px;
        background-color: #202020;
        color: #ffffff;
        height: 40px;
        background-image: url('../../img/down_arrow_yellow.svg');
        font-size: 14px;
        border: 1px solid #202020;
      }
    }

    .btn-group {
      button {
        padding: 12px 28px;
        height: 44px;
        border-radius: 7px;
        width: auto;
        font-size: 14px;

        &.filter-btn-inactive {
          background-color: #101010;
          border: 1px solid #909090;

          &:hover {
            background-color: rgba(109, 177, 188, 0.2);
            color: #6db1bc;
            border: 1px solid #6db1bc;
            transition: all 0.2s ease-in;
          }
        }

        &.filter-btn-active {
          background-color: #6db1bf;
          border: 1px solid #6db1bf;
        }

        svg {
          margin: 0px !important;
        }
      }

      &.filter_plugin_group {
        justify-content: flex-end;
        margin-bottom: 20px;
        flex: 0 0 calc(100% - 390px);
        max-width: calc(100% - 390px);
      }
    }
  }

  .checkbox_private_public {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 24px;
    gap: 15px;

    .form-check {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      padding: 0;
      margin: 0;

      .form-check-input {
        margin: 0;
        border-radius: 1px;
        width: 11px;
        height: 11px;
      }
    }
  }

  .environment_select_redirect_btn {
    padding-left: 15px;

    .select_redirect_btn {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 0px;
      min-width: 60px;

      // .environment-select {
      //     width: 100% !important;
      //     max-width: 365px;
      //     background-color: #202020;
      //     color: #ffffff;
      //     height: 40px;
      //     background-image: url("../../img/down_arrow_yellow.svg");
      //     font-size: 14px;
      //     border: 1px solid #202020;
      // }
    }

    h6 {
      margin-bottom: 20px;
    }
  }
}

// plugin blocks
.plugin_row {
  margin: 0 -20px;

  .plugin_columns {
    padding: 0 20px;
    margin-bottom: 40px;

    &.listmode {
      .plug_grid {
        .plug_img {
          background-color: transparent;
          overflow: hidden;

          img {
            height: 155px !important;
            // object-fit: cover;
            // background-color: #101010;
          }
        }
      }
    }

    .plug_grid {
      .plug_img {
        background-color: #101010;
        overflow: hidden;
        border-radius: 15px;

        img {
          height: 256px !important;
          object-fit: cover;
        }
      }
    }
  }
}

// Store List view

.plug_grid {
  &.list_view_store {
    .plug_img,
    .list {
      border-right: 1px solid #101010;
    }

    .list {
      padding-left: 25px;
    }

    .product_image_dec {
      display: flex;
      align-items: center;
      gap: 20px;

      .product_image {
        flex: 0 0 124px;
        max-width: 124px;
        position: relative;

        .product_like {
          width: 42px;
          height: 42px;
          top: 3px;
          right: 3px;
        }

        .store-item-image {
          width: 124px;
          height: 98px;
          object-fit: cover;
          border: 0px !important;
        }
      }
    }

    .list_visible {
      .tag_cat {
        .cattag_lst {
          span {
            background: #2c2c2c;
            color: #909090;
          }
        }
      }
    }

    .plugin_select_btn {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      align-content: center;
      justify-content: center;

      .back_btn {
        line-height: 1;
      }
    }

    .list_tags_updates {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      align-content: center;

      .up_date {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .list_visible {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

/*Selected Plugins */
.rmsc.multi-select {
  width: 250px;

  .select-item.selected,
  .select-item {
    background: transparent !important;
    padding: 0 5px !important;
  }

  .dropdown-container {
    background-color: $secondary-bg;
    color: $secondary-color;
    border-color: transparent;

    &:focus-within {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: transparent;
    }
  }

  .gray {
    color: #6eb1bf;
  }

  .search {
    border-bottom: none !important;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border: none;
    border-collapse: collapse;
    color: #5f5f5f;
    font-weight: normal;

    .table th,
    .table td {
      color: #5f5f5f;
      font-weight: normal;
      border-top: 1px solid #1f1f1f;
      border-bottom: none;
      border-left: none;
      border-right: none;
      white-space: nowrap;
      padding: 15px 15px;
      vertical-align: middle;
    }
  }
}

.table th {
  border-top: none;
}

.table > :not(:first-child) {
  border-top: 1px solid #1f1f1f;
}

.plg_area {
  gap: 10px;

  .pluginnm_url {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .plg_nm {
      color: #ffffff;
      font-size: $font20;
      font-weight: 600;
    }
  }
}

.dropdown-content *:not(.form-check-input),
.dropdown-content {
  background-color: $secondary-bg;
  color: $secondary-color;
  border-color: transparent;
}

.listmode .plugin_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .pluginname {
    margin-top: 0;
  }
}

.listmode .pricesection {
  justify-content: center;
  align-items: center;
  display: flex;
  border-left-style: solid;
  border-left-color: $secondary-bg;
}

.listmode .pricesection .row {
  flex-direction: column;

  .store_primary_btn {
    margin-top: 20px;
  }
}

.listmode .pricesection .row > div {
  flex-basis: 100% !important;
  width: 100%;
}

.listmode .pricesection .row > div .text-end {
  text-align: left !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: relative;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  transform: translateX(-50%) translateY(-5px);
}

.tag_cat {
  display: flex;
  margin-top: 15px;
  column-gap: 10px;
  flex-wrap: wrap;

  .cattag_lst {
    display: flex;
    gap: 10px;
  }

  .tag_lst,
  .categories_lst {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .cattag_lst span {
    background: #202020;
    color: #888888;
    padding: 5px 10px;
    line-height: 1;
    display: inline-block;
    border-radius: 5px;
    margin: 4px 0px;
  }
}

header.header {
  background: $primary-bg;
  border-left: 1px solid $primary-color;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  display: block;
  z-index: 9;
  width: 100%;
  max-width: calc(100% - 60px);
  height: 50px;
  padding: 12.5px 0 12.5px;
  -moz-transition: max-width 0.6s ease-out;
  -o-transition: max-width 0.6s ease-out;
  -webkit-transition: max-width 0.6s ease-out;
  transition: max-width 0.6s ease-out;
}

// header.store_header {
//   // max-width: calc(100% - 310px);
// }

header.header.header--slid {
  max-width: calc(100% - 200px);
}

// header.store_header.header--slid {
//   max-width: calc(100% - 450px);
// }

header.header + .main {
  margin-top: 50px;
}

.breadcrumb {
  color: #909090;
  font-size: $font14;
}

.breadcrumbs {
  font-size: $font14;
}

.breadcrumbs > * {
  display: inline-block;
  margin-right: 5px;
}

.breadcrumbs .crumb:after {
  content: '/';
  margin-left: 5px;
}

.breadcrumbs .crumb:last-child:after {
  display: none;
}

.breadcrumbs .crumb a {
  color: #ddd;
  text-decoration: none;
}

.breadcrumbs .crumb .activeCrumb {
  color: $primary-color;
}

.sticky-container {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #5f5f5f;
  //   border-radius: 10px;
  //   position: fixed;
  //   top: 45px;
  //   right: 200px;
  //   display: flex;
  //   justify-content: flex-end;
  //   padding: 10px;
  //   background-color: #5f5f5f;
}

.sticky {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.header_sticky_container {
  // position: fixed;
  // bottom: 0;
  // right: 0;
  // display: flex;
  // justify-content: flex-end;
  // padding: 10px;
  // background-color: #5f5f5f;

  border-radius: 10px;
  position: fixed;
  top: 45px;
  right: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #5f5f5f;
}

.header_sticky {
  position: fixed;
  top: 0;
  right: 187px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.5em;
  position: absolute;
  top: 0;
  right: 0;
}

.selected-plugin,
.sticky-button {
  padding: 5px 10px;
}

.selected-plugin {
  margin: 10px;
}

.sticky-button {
  margin-left: 10px;
}
