.store_banner {
  background-color: #202020;
  padding: 50px 20px 30px;

  .store_header {
    // margin-bottom: 50px;

    .store_search {
      .search {
        .search_main {
          input {
            background: #101010;
            width: 400px;
          }
        }
      }
    }

    .form-control {
      border-radius: 0px !important;
    }

    .reset_filter {
      margin-left: 10px;

      svg {
        margin: 0px !important;
      }
    }
  }
}

.plugins_list {
  padding-top: 40px;
  padding-bottom: 20px;

  .plugins_header {
    padding-bottom: 18px;

    .plugins_titles {
      .plugins_header_title {
        font-size: 16px;
        font-weight: 500;
      }

      .plugins_header_subtitle {
        margin-bottom: 0px;
      }
    }
  }

  .product_like {
    transition: 0.15s ease-in-out;

    &:hover {
      box-shadow: 0px 0px 10px 1px #fff;

      svg {
        filter: drop-shadow(0px 0px 10px #fff);
      }
    }

    svg {
      transition: 0.15s ease-in-out;
    }
  }

  .plug_grid {
    text-decoration: none;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #101010;
    border-width: 1px;
    border-style: solid;
    border-color: #404040 !important;
    transition: 0.15s ease-in-out !important;

    &:hover {
      border: 1px solid #fff;
      box-shadow: 0px 0px 8px #fff;
      background-color: #202020 !important;
    }

    .plugin_select_btn {
      border-radius: 100% !important;
      transition: 0.15s ease-in-out;

      &:hover {
        // box-shadow: 0px 0px 10px #f08921;

        svg {
          cursor: pointer;
          color: #f08921 !important;
        }

        .fa-circle-minus {
          color: red !important;
        }

        .fa-trash {
          cursor: pointer;
          color: red !important;
          transition: 0.16s ease-in-out;
          filter: drop-shadow(0px 0px 5px red) !important;
        }
      }

      svg {
        transition: 0.15s ease-in-out;
        color: white;
      }
    }

    .plugins_info {
      .plugins_item_header {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .plugin_icon {
          flex: 0 0 44px;
          max-width: 44px;
          height: 44px;
          margin-right: 0;

          img {
            object-fit: cover;
            width: 100%;
            height: 44px;
          }
        }

        .plugins_title_author {
          flex: 0 0 calc(100% - 64px);
          max-width: calc(100% - 64px);

          .plugins_item_title {
            h6 {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              overflow: hidden;
              white-space: unset;
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 0px;
              color: #fff;
            }
          }

          .plugins_item_author {
            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              padding-bottom: 0px;
              margin-bottom: 0px;
            }

            .plugins_item_author_name {
              color: #f08921;
            }
          }
        }
      }

      .plugins_item_description {
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin: 0 0 24px;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          height: 60px;
          text-overflow: ellipsis;
        }
      }
    }

    .plugins_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;

      .plugins_item_pricing {
        p {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 0px;

          .plugins_item_period {
            color: #909090;
            font-weight: 400;
          }
        }
      }

      .plugins_item_additional_info {
        .plugins_item_ratings {
          line-height: 1;
          display: flex;
          align-items: center;

          .plugins_item_rating_value {
            line-height: 1;

            p {
              font-size: 14px;
              font-weight: 500;
              line-height: 1;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

.marketplace_footer {
  background-color: #202020;
  padding: 50px 20px 30px;

  .marketplace_footer_inner {
    .marketplace_title {
      margin-bottom: 50px;

      h2 {
        font-size: 32px;
        line-height: 1.2;
        max-width: 390px;
        margin-bottom: 20px;
      }
    }
  }
}

.mid_banner {
  .mid_banner_inner {
    padding: 60px 42px 50px;
    background-color: #202020;

    .mid_banner_blocks {
      .mid_banner_img_views {
        max-width: 380px;
        margin: 0 auto;
        position: relative;

        .mid_banner_img {
          width: 100%;
        }

        .language_logo {
          position: absolute;
          background: rgba(245, 245, 245, 0.3);
          line-height: 1;
          backdrop-filter: blur(7px);
          border-radius: 4px;
          padding: 12px 14px;
          top: 30px;
          right: -33px;
        }

        .mid_banner_views {
          position: absolute;
          background: rgba(245, 245, 245, 0.3);
          line-height: 1;
          backdrop-filter: blur(7px);
          border-radius: 4px;
          padding: 12px 14px;
          bottom: 48px;
          left: -40px;

          .mid_banner_views_amount {
            font-weight: 700;
          }
        }
      }

      .mid_banner_info {
        h2 {
          font-size: 30px;
          line-height: 1.2;
          max-width: 480px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.search_btn_active {
  background-color: #f08921;
}

.search_heading {
  color: #f08921;
}

.search_btn {
  color: white;

  &:hover {
    color: #f08921;
    background-color: rgba(240, 137, 33, 0.2);
  }
}

.search-bar {
  width: 100%;
  max-width: 500px;
  position: relative;
  top: auto;

  .search_bar_inner {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    input {
      background-color: #101010;
      border: 1px solid #101010;
      color: #ffffff;

      &::placeholder {
        color: #909090;
      }

      &:focus {
        outline: none;
        box-shadow: 0px 0px 5px 1px #6db1bc !important;
      }

      &.form-control {
        border-radius: 8px !important;
      }
    }

    svg {
      path {
        // fill: #6DB1BC;
        fill: #f08921;
      }
    }
  }
}

.search-bar-sticky {
  width: 100%;
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 1;
  background-color: #202020;
  padding: 10px;

  &.search_filterd_menu {
    gap: 40px;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 70px;
    justify-content: space-between;

    .search-bar {
      max-width: 400px;
      order: 2;
    }

    .filterd-five {
      flex: 0 0 calc(100% - 450px);
      max-width: calc(100% - 450px);
      order: 1;
    }
  }
}

.filterd-five {
  flex: 0 0 100%;
  max-width: 100%;
}

.search_filterd_menu {
  gap: 50px;
  flex-wrap: wrap;
}

.plugins_actions {
  flex: 0 0 110px;
  max-width: 110px;
  height: 55px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.dropdown {
  .search_btn {
    &.show {
      color: #f08921;
      background-color: rgba(240, 137, 33, 0.2);
    }
  }
}
