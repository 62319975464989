.addproject {
  max-width: 1200px;
  margin: 0 auto;
  input,
  select,
  textarea {
    background-color: transparent;
    font-size: $font14;
    border: 1px solid $third-color;
    color: $darkgray;
    &:focus {
      background-color: transparent;
      border: 1px solid $third-color;
      box-shadow: none;
      color: $darkgray;
    }
  }

  .input-group-text {
    width: 250px;
    font-size: $font14;
    background-color: $third-bg;
    border: 1px solid $third-color;
    color: white;
    white-space: break-spaces;
  }
  .upload {
    width: 100%;
    height: 40px;
    border-left: 1px solid #f28a03;
    border-width: 0 0 0 1px;
    border-radius: 0;
  }

  .form-check-group {
    display: flex;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border-radius: 0px 8px 8px 0px !important;
    border: 1px solid #505050;
    align-items: center;
    gap: 30px;
    padding: 6px 12px !important;

    .form-check {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .file-btn {
    background-color: $bluesecondary;
    color: white;
    padding: 0 15px;
    // padding-right: 15px;
    border: 1px solid $bluesecondary;
    border-radius: 3px;
    font-size: $font14;
  }
  .orange {
    background-color: $primary-color;
    border: 1px solid $primary-color;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2b2b2b;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #f28a03;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2b2b2b;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .main_auth {
    background-color: #101010;
    min-height: 100vh;
    padding-bottom: 60px;
  }
  .login_register span {
    color: #505050;
  }

  .login_register {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .green_border {
    border: 1px solid $green-color;
    color: #ffffff;
    border-radius: 7px;
    padding: 9px 22px;
    font-size: $font14;
    transition: $transition-ease;
  }
  .red_border {
    border: 1px solid $red-color;
    color: #ffffff;
    border-radius: 7px;
    padding: 9px 22px;
    font-size: $font14;
    transition: $transition-ease;
  }
  .green_border-dashed {
    border: 1px dashed $green-color !important;
  }
  .red_border-dashed {
    border: 1px dashed $red-color !important;
  }
}

.drag_drop_section {
  max-width: 1200px;
  margin: 40px auto 50px;

  .upload_file_title {
    background-color: #2b2b2b;
    border-left: 1px solid #f28a03;
    p {
      color: #909090;
      font-size: 16px;
      margin: 0;
      padding: 18px 20px;
      font-weight: 400;
    }
  }
  .drag_drop_block {
    .drag_drop_block_inner {
      border: 1px dashed #707070;
      // background-image: url(../../img/dashed_line.svg);
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: contain;
      padding: 45px 40px 52px 40px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 36px;

      h5 {
        color: #ffffff;
        font-size: 22px;
        line-height: 33px;
        margin-bottom: 20px;
        font-weight: 400;
      }

      .or_text {
        color: #909090;
        font-size: 22px;
        line-height: 33px;
        margin-bottom: 20px;
        font-weight: 400;
      }

      button {
        margin: 0 auto;
      }
    }
    .file_upload_progressbar {
      .file_title {
        p {
          color: #fff8f8;
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 7px 0;
          font-weight: 400;
        }
      }
      .file_size_status {
        background-color: #6db1bf;
        height: 20px;
        max-width: 600px;
        p {
          color: #fff;
          font-size: 14px;
          line-height: 20px;
          margin: 0;
          font-weight: 400;
          text-align: center;
        }
      }
      .file_size {
        color: #909090;
        font-weight: 400;
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
}
