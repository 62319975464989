.accordion-container {
  position: relative;
  width: calc(100% - 60px);
  top: 60px;
  left: 30px;
  right: 13px;
  h1 {
    color: white;
    font-size: 20px;
    margin-bottom: 45px;
  }
}

.styled-accordion .accordion-button {
  background-color: rgba(32, 32, 32, 0.8);
  color: white;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.styled-accordion .accordion-button:not(.collapsed) {
  background-color: rgba(32, 32, 32, 0.8);
  color: white;
}

.styled-accordion .accordion-item {
  border: none;
}

.styled-accordion .accordion-body {
  background-color: #101010;
  color: white;
  border: none;
  box-shadow: none;
}
.addprojects {
  display: flex;
  h5 {
    text-align: center;
    margin-top: 7px;
    margin-right: 25px;
  }
}
.username-label {
  width: 100px;
  height: 40px;
}
.username-value {
  width: 125px;
  height: 40px;
}
