.ticket_details {
  max-width: 1044px;
  .in_progress {
    background-color: #13a53f;
    color: #ffffff;
    border: 1px solid #13a53f;
    border-radius: 7px;
    padding: 5px 23px;
    font-size: 14px;
    transition: all 0.3s ease-in;
    cursor: default;
  }
  .add_comment {
    display: flex;
    gap: 33px;
    align-items: flex-start;
    flex-wrap: wrap;
    .comment_box {
      border: 1px solid #505050;
      flex: 1 1 auto;
      border-radius: 7px;
      .comment_input {
        background-color: transparent;
        font-size: 14px;
        border: none;
        color: #909090;
        height: 60px;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
      .file-upload {
        position: relative;
        .file-inputbox {
          background: #2b2b2b;
          color: #909090;
          border: none;
          border-radius: 7px 0px 0px 7px;
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
        .file-btn {
          position: absolute;
          right: 0;
          background: #2b2b2b;
          color: #909090;
          border: none;
          top: 50%;
          transform: translateY(-50%);
          font-size: 14px;
        }
      }
      .cblue_btn {
        border-radius: 0px 7px 7px 0px;
      }
    }
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .comment_listing {
    .comment_item {
      color: #ffffff;
      .usr_avatar {
        display: flex;
        gap: 33px;
        align-items: flex-start;
        flex-wrap: wrap;
        .usr_img_area {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
        }
        .comment_details {
          flex: 1 1 auto;
          .user_name {
            display: flex;
            justify-content: space-between;
            gap: 10px;
          }
        }
      }
    }
  }
}
