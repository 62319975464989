@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/_variables-dark.scss';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }
    .border#{$infix}-width-1 {
      border-width: 1px !important;
    }
    .border#{$infix}-width-0 {
      border-width: 0px !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}
