.plugin_listab {
  .nav-tabs {
    border-bottom: 2px solid $secondary-bg;
    &.plugin_listab_top {
      .nav-item {
        padding: 8px 16px 0 !important;
        .nav-link {
          background: none;
          border: none;
          color: $secondary-color;
          font-size: $font14;
          padding: 0 0 8px;
          &.active {
            background: none;
            border: none;
            color: #ffffff;
            border-style: solid;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-image: none;
            border-color: #f08921;
            border-width: 3px;
            margin-bottom: -2px;
            box-shadow: 0px -12px 5px -10px inset #f08921;
            border-radius: 2px;
            // padding: 0 0 8px;
          }
          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    &.plugin_listab_bottom {
      border-bottom: 1px solid #505050;
      padding: 0 15px;
      margin-bottom: 60px;
      .nav-item {
        .nav-link {
          margin-bottom: -1px;
          background: 0 0;
          border: 1px solid transparent;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          color: #505050;
          &.active {
            color: #fff;
            background-color: #101010;
            border-color: #505050;
            border-bottom: 1px solid #101010;
            box-shadow: 0px -12px 5px -10px inset #f08921;
            border-radius: 2px;
          }
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
  .list_header {
    .total_plug_sort {
      margin-top: 64px;
      .sort_filter {
        margin-top: -45px;
        .sorttxt {
          font-size: 14px;
        }
        .selectbox {
          width: 100% !important;
          max-width: 210px;
          background-color: #202020;
          color: #888888;
          height: 44px;
          background-image: url('../../img/down_arrow_yellow.svg');
          font-size: 14px;
          border: 1px solid #202020;
        }
      }
    }
  }
}
.plugin-second {
  .select_filter_group {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    justify-content: space-between;

    .select_env {
      .environment-select {
        width: 100% !important;
        max-width: 365px;
        background-color: #202020;
        color: #ffffff;
        height: 40px;
        background-image: url('../../img/down_arrow_yellow.svg');
        font-size: 14px;
        border: 1px solid #202020;
      }
    }

    .btn-group {
      button {
        padding: 12px 28px;
        height: 44px;
        border-radius: 7px;
        width: auto;
        font-size: 14px;

        &.filter-btn-inactive {
          background-color: #101010;
          border: 1px solid #909090;

          &:hover {
            background-color: rgba(109, 177, 188, 0.2);
            color: #6db1bc;
            border: 1px solid #6db1bc;
            transition: all 0.2s ease-in;
          }
        }

        &.filter-btn-active {
          background-color: #6db1bf;
          border: 1px solid #6db1bf;
        }

        svg {
          margin: 0px !important;
        }
      }

      &.filter_plugin_group {
        justify-content: flex-end;
        margin-bottom: 20px;
        flex: 0 0 calc(100% - 390px);
        max-width: calc(100% - 390px);
      }
    }
  }

  .checkbox_private_public {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 24px;
    gap: 15px;

    .form-check {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      padding: 0;
      margin: 0;

      .form-check-input {
        margin: 0;
        border-radius: 1px;
        width: 11px;
        height: 11px;
      }
    }
  }

  .environment_select_redirect_btn {
    padding-left: 15px;

    .select_redirect_btn {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 36px;
    }

    h6 {
      margin-bottom: 20px;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border: none;
    border-collapse: collapse;
    color: #5f5f5f;
    font-weight: normal;
    th {
      &:first-child {
        width: 300px;
      }
    }
    .table td {
      color: #5f5f5f;
      font-weight: normal;
      border-top: 1px solid #1f1f1f;
      border-bottom: none;
      border-left: none;
      border-right: none;
      white-space: nowrap;
      padding: 15px 15px;
      vertical-align: middle;
    }
  }
}
// .add-package-form {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-evenly;
// }
.selectbox {
  background-image: $accordion-button-icon;
  background-color: $secondary-bg;
  color: $secondary-color;
  border: none;
  font-size: $font14;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.package_version {
  flex-basis: 20%;
  margin-top: 10px;
  padding-top: 20px;
  background-color: #2b2b2b;
  height: 550px;
}
.package_version img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.addpackage {
  flex-basis: 65%;
  margin: 10px;
  max-width: 1200px;
  input,
  select,
  textarea {
    background-color: transparent;
    font-size: $font14;
    border: 1px solid $grey-color;
    color: $darkgray;
    padding: 8px;
    border-radius: 3px;
    &:focus {
      background-color: transparent;
      border: 1px solid $grey-color;
      box-shadow: none;
      color: $darkgray;
      padding: 8px;
      border-radius: 3px;
    }
    &:disabled {
      background-color: transparent;
      border: 1px solid $grey-color;
      box-shadow: none;
      color: $darkgray;
      padding: 8px;
      border-radius: 3px;
    }
  }
  .dependancy {
    border: 1px solid #505050;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 15px;
  }
  .input-group-text {
    width: 150px;
    padding: 10px;
    border-radius: 3px;
    font-size: $font14;
    background-color: $third-bg;
    border: 1px solid $third-color;
    color: white;
  }
  .upload {
    width: 100%;
  }
}
.version-select {
  padding: 10px;
  border-radius: 7px;
  background-color: $third-bg;
  color: $secondary-color;
  font-size: $font14;
  border: 1px solid $secondary-bg;
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $secondary-bg !important;
  }
}
.package {
  font-size: 14px;
  color: #fff;
}
.package-inner {
  padding: 15px;
  color: #fff;
  font-size: 14px;
}
.package-inner_click {
  padding: 5px;
  color: $darkgray;
  font-size: 14px;
  margin-left: 15px !important;
  cursor: pointer;
}
.package-inner_click li {
  list-style: none;
}
.search {
  margin-bottom: 15px;

  .search_main {
    position: relative;
    // z-index: 2;
    input {
      width: 250px;
      border: none;
      background: $secondary-bg;
      color: #ffffff;
      font-size: $font14;
      border-radius: 6px;
      height: 44px;
      padding: 0px 45px 0px 15px;
      &:focus {
        outline: none;
      }
    }
    svg {
      position: absolute;
      right: 16px;
      max-height: 16px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}
.add {
  margin-left: 90% !important;
  margin-bottom: 10px;
}
.fade.modal-backdrop.show {
  background-color: $grey-color;
}
.popup-modal {
  .modal-modal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .modal-header {
    border: none;
  }
  .modal-footer {
    border: none;
  }
  .modal-content {
    background-color: #101010;
  }
  .modal-version {
    flex-basis: 25%;
    border: 1px solid $darkgray;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 15px;
    height: 400px;
  }
  .input-btn {
    display: flex;
  }
  .input {
    background-color: $primary-bg;
    margin: 10px 0;
    border: 2px solid $darkgray;
  }
  .button {
    margin: 10px;
  }
}

.addpackage {
  flex-basis: 65%;
  margin: 10px;
  max-width: 1200px;
  input,
  select,
  textarea {
    background-color: transparent;
    font-size: $font14;
    border: 1px solid $grey-color;
    color: $darkgray;
    padding: 8px;
    border-radius: 3px;
    &:focus {
      background-color: transparent;
      border: 1px solid $grey-color;
      box-shadow: none;
      color: $darkgray;
      padding: 8px;
      border-radius: 3px;
    }
    &:disabled {
      background-color: transparent;
      border: 1px solid $grey-color;
      box-shadow: none;
      color: $darkgray;
      padding: 8px;
      border-radius: 3px;
    }
  }
  .dependancy {
    border: 1px solid #505050;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 15px;
  }
  .input-group-text {
    width: 150px;
    padding: 10px;
    border-radius: 3px;
    font-size: $font14;
    background-color: $third-bg;
    border: 1px solid $third-color;
    color: white;
  }
  .upload {
    width: 100%;
  }
}
.version-select {
  padding: 10px;
  border-radius: 7px;
  background-color: $third-bg;
  color: $secondary-color;
  font-size: $font14;
  border: 1px solid $secondary-bg;
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $secondary-bg !important;
  }
}
.package {
  font-size: 14px;
  color: #fff;
}
.package-inner {
  padding: 15px;
  color: #fff;
  font-size: 14px;
}
.package-inner_click {
  padding: 5px;
  color: $darkgray;
  font-size: 14px;
  margin-left: 15px !important;
  cursor: pointer;
}
.package-inner_click li {
  list-style: none;
}
.tab-content {
  .addproject {
    .upload {
      width: 100%;
      border-radius: 0;
      border: none;
      border-left: 1px solid #f28a03;
      height: 40px;
    }

    .file-btn {
      background-color: $primary-color;
      color: #ffffff;
      border: 1px solid $primary-color;
      border-radius: 7px;
      padding: 9px 22px;
      font-size: 14px;
      transition: all 0.2s ease-in;

      &:hover {
        background-color: rgba(240, 137, 33, 0.2);
        color: $primary-color;
        border-radius: 7px;
      }

      label {
        cursor: pointer;
      }
    }

    .form-check-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      padding: 0 25px 0 13px;
      width: calc(100% - 250px);
      background-color: transparent;
      font-size: 14px;
      border: 1px solid #505050;
      color: #909090;
      border-radius: 0 6px 6px 0;

      .form-check {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 0;

        .form-check-input[type='radio'] {
          border-radius: 0;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
