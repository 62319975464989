.auth-wrap {
  min-height: 100vh;
  .auth {
    max-width: 500px;
    width: 100%;
    .otp-text {
      color: $secondary-color;
    }
    input {
      border: 1px solid $secondary-color;
      background: transparent;
      color: #ffffff;
    }
  }
}
.main_auth_inner {
  padding: 0 15px;
  max-width: 550px;
  margin: 0 auto;
}
.home_logo {
  text-align: center;
  margin-bottom: 50px;
  padding-top: 100px;

  img {
    max-height: 70px;
  }
}
.input {
  display: flex;
  gap: 10px;
}
.input-container {
  position: relative;
  margin-bottom: 15px;
}
.icon {
  padding: 20px 0;
  background: transparent;
  color: #f28a03;
  min-width: 50px;
  position: absolute;
  height: 20px;
}
.icon_sec {
  left: auto;
  right: 0;
  color: #6db1bf;
}
.input-field {
  width: 100%;
  padding: 15px 43px;
  outline: none;
  background-color: transparent;
  border: 1px solid #909090;
  color: #ffffff;
  border-radius: 3px;
  font-size: 18px;
}
.btn_submit {
  width: 100%;
  padding: 10px 30px;
  border-radius: 0;
  background-color: #f28a03;
  color: #ffffff;
  border: none;
  margin-top: 15px;
}
.login_register {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 15px;

  span {
    color: #505050;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  background: #2b2b2b;
  border-radius: 100px;
  transition: background-color 0.2s;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #f28a03;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.rowc {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .col-md-6c {
    flex-basis: calc(50% - 7.5px);
  }
}
.tc {
  color: #909090;
  font-size: 18px;
  margin-left: 10px;

  a {
    color: #f28a03;
    text-decoration: none;
  }
}
.log_reg_with {
  text-align: center;
  color: #909090;
  margin-top: 40px;
  font-size: 18px;
}
.forget_password {
  text-align: right;
  font-size: 18px;
  a {
    color: #f28a03;
    text-decoration: none;
  }
}
.social_login {
  margin-top: 40px;
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 15px;

    li {
      background: #2b2b2b;
      padding: 15px;
      border-radius: 50%;
      display: inline-block;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #fff;
        // color: black;
      }
    }
    li a {
      text-decoration: none;
      color: #f28a03;
    }
  }
}
.return_to_home {
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
  a {
    color: #f28a03;
    text-decoration: none;
  }
}
